import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as pageRoutes from "./pageRoutes";

import PageRoutingSkeleton from "./components/common/PageRoutingSkeleton/PageRoutingSkeleton";
import Layout from "./components/common/Layout/Layout";

import Login from "./components/Login/Login";
import temp from "./components/common/temp/temp";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import SignUp from "./components/Signup/Signup";
import Dashboard from "./components/Dashboard/Dashboard";
import { EDIT, PRICE_GROUP, PRICE_LEADER, SUPPRESSION, WATCHLIST } from "./components/common/constants";
import { isThisFeatureAllowed } from "./components/common/common";

const ProductGaps = lazy(() => import("./components/ProductGaps/ProductGaps"));
const BrandGaps = lazy(() => import("./components/BrandGaps/BrandGaps"));
const ProductGapsUbr = lazy(() => import("./components/ProductGapsUbr/ProductGapsUbr"));
const ProductGapsSupressed = lazy(() => import("./components/ProductGapsSupressed/ProductGapsSupressed"));
const BrandGapsSupressed = lazy(() => import("./components/BrandGapsSupressed/BrandGapsSupressed"));
const ProductOnboarding = lazy(() => import("./components/Onboarding/ProductOnboarding"));
const BrandOnboarding = lazy(() => import("./components/Onboarding/BrandOnboarding"));
const DashboardPricing = lazy(() => import("./components/DashboardPricing/DashboardPricing"));
const ProductsPricing = lazy(() => import("./components/ProductsPricing/ProductsPricing"));
const ProductPricing = lazy(() => import("./components/ProductPricing/ProductPricing"));
const CompProductPricing = lazy(() => import("./components/CompProductPricing/CompProductPricing"));
const PriceLeaderAssortment = lazy(() => import("./components/PriceLeaderAssortment/PriceLeaderAssortment"));
const PriceLeaderBrandAssortment = lazy(() => import("./components/PriceLeaderBrandAssortment/PriceLeaderBrandAssortment"));
const PriceLeaderPricing = lazy(() => import("./components/PriceLeaderPricing/PriceLeaderPricing"));
const PriceLeaderBrandPricing = lazy(() => import("./components/PriceLeaderBrandPricing/PriceLeaderBrandPricing"));
const EditCategory = lazy(() => import("./components/EditCategory/EditCategory"));
const UserGuide = lazy(() => import("./components/UserGuide/UserGuide"));
const UserGuideEdit = lazy(() => import("./components/UserGuide/UserGuideEdit"));
const FavoriteProduct = lazy(() => import("./components/Favorites/FavoriteProducts"));
const FavoriteBrand = lazy(() => import("./components/Favorites/FavoriteBrands"));
const Reports = lazy(() => import("./components/Reports/Reports"));
const MatchConverage = lazy(() => import("./components/Reports/MatchCoverage"));
const MatchOpportunities = lazy(() => import("./components/Reports/MatchOpportunities"));
const NormalizedBrands = lazy(() => import("./components/NormalizedBrands/NormalizedBrands"));
const PricingRules = lazy(() => import("./components/PricingRules/PricingRules"));
const PricingRulesList = lazy(() => import("./components/PricingRules/PricingRulesList"));
const PricingSuppressionsNav = lazy(() => import("./components/PricingSuppressions/PricingSuppressionsNav"));
const ProductsPricingRule = lazy(() => import("./components/ProductsPricingRule/ProductsPricingRule"));
const ProductsPricingRuleGroup = lazy(() => import("./components/ProductsPricingRule/ProductsPricingRuleGroup"));
const ProductPricingSystemRules = lazy(() => import("./components/ProductsPricingRule/ProductPricingSystemRules"));
const ProductPricingRuleHistory = lazy(() => import("./components/ProductsPricingRule/ProductPricingRuleHistory"));
const ProductsPricingSales = lazy(() => import("./components/ProductsPricingSales/ProductsPricingSales"));
const ProductPricingRule = lazy(() => import("./components/ProductPricingRule/ProductPricingRule"));
const ProductsPricingWeightedCpi = lazy(() => import("./components/ProductsPricingWeightedCpi/ProductsPricingWeightedCpi"));
const PricingRulesNav = lazy(() => import("./components/PricingRules/PricingRulesNav"));
const PricingFeeds = lazy(() => import("./components/Reports/PricingFeeds"));
const OutlierReport = lazy(() => import("./components/Reports/OutlierReport"));
const MapViolationReport = lazy(() => import("./components/Reports/MapViolationReport"));
const PriceChangeReport = lazy(() => import("./components/Reports/PriceChangeReport"));
const AdminDashboard = lazy(() => import("./components/AdminTools/AdminDashboard"));
const TpvrReports = lazy(() => import("./components/Reports/TpvrReports"));
const ExecutiveSummary = lazy(() => import("./components/Reports/ExecutiveSummary"));
const PriceComparisonReport = lazy(() => import("./components/Reports/PriceComparisonReport"));
const ProductsGroupPricing = lazy(() => import("./components/ProductsPricing/ProductsGroupPricing"));
const ProductsPricingRuleReport = lazy(() => import("./components/ProductsPricingRule/ProductsPricingRuleReport"));
const NotificationsPage = lazy(()=> import('./components/common/Notifications/NotificationsPage'));


const Routess = () => {
    const routingStateLoader = () => {
        return (
            <Layout>
                <PageRoutingSkeleton />
            </Layout>
        );
    };
    return (
        <Router>
            <Suspense fallback={routingStateLoader()}>
                <Routes>
                    <Route exact path={pageRoutes.passThrough} element={<Login  loginType = "passThrough" />} />
                    <Route exact path={pageRoutes.loginPage} element={<Login />} />
                    <Route exact path={pageRoutes.signUpPage} element={<SignUp />} />
                    <Route exact path={pageRoutes.dashboardPage} element={<Layout><Dashboard /></Layout>} />
                    <Route exact path={pageRoutes.productGapsPage} element={<Layout><ProductGaps /></Layout>} />
                    <Route exact path={pageRoutes.productGapsUniqueByRetailersPage} element={<Layout><ProductGapsUbr /></Layout>} />
                    <Route exact path={pageRoutes.brandGapsPage} element={<Layout><BrandGaps /></Layout>} />
                    <Route exact path={pageRoutes.dashboardPricingPage} element={<Layout><DashboardPricing /></Layout>}/>
                    <Route exact path={pageRoutes.productsPricingPage} element={<Layout><ProductsPricing /></Layout>}/>
                    <Route exact path={pageRoutes.productPricingPage} element={<Layout><ProductPricing /></Layout>}/>
                    <Route exact path={pageRoutes.competitorPricingPage} element={<Layout><ProductsPricing /></Layout>}/>
                    <Route exact path={pageRoutes.compProductPricingPage} element={<Layout><CompProductPricing /></Layout>}/>
                    <Route exact path={pageRoutes.userGuidePage} element={<Layout><UserGuide /></Layout>}/>
                    <Route exact path={pageRoutes.userGuideEditPage} element={<Layout><UserGuideEdit /></Layout>}/>
                    <Route exact path={pageRoutes.matchesPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.matchReportsTpvrPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.matchReportsTpvrManagerPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.matchReportsPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.matchReportsSlaPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.matchCoveragePage} element={<Layout><MatchConverage /></Layout>}/>
                    <Route exact path={pageRoutes.matchOpportunitiesPage} element={<Layout><MatchOpportunities /></Layout>}/>
                    <Route exact path={pageRoutes.matchCoverageCategoryPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.matchCoverageRetailerePage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.matchCoveragePriorityPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.pricingPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.pricingSummaryPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.assortmentWowPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.pricingApproveRejectSummaryPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.pricingRulesPage} element={<Layout><PricingRulesNav /></Layout>} />
                    <Route exact path={pageRoutes.pricingRulesUpdatePage} element={<Layout><PricingRulesNav /></Layout>} />
                    <Route exact path={pageRoutes.pricingRulesListPage} element={<Layout><PricingRulesNav /></Layout>} />
                    <Route exact path={pageRoutes.pricingRulesGroupListPage} element={<Layout><ProductsPricingRuleGroup /></Layout>} />
                    <Route exact path={pageRoutes.pricingSystemRulesPage} element={<Layout><ProductPricingSystemRules /></Layout>} />
                    <Route exact path={pageRoutes.pricingSuppressionsNav} element={<Layout><PricingSuppressionsNav /></Layout>} />
                    <Route exact path={pageRoutes.pricingProductSuppressionsListPage} element={<Layout><PricingSuppressionsNav /></Layout>} />
                    <Route exact path={pageRoutes.productsPricingRulesPage} element={<Layout><ProductsPricingRule /></Layout>}/>
                    <Route exact path={pageRoutes.suggestionsReportsPage} element={<Layout><ProductsPricingRuleReport /></Layout>}/>
                    <Route exact path={pageRoutes.executiveSummaryReportsPage} element={<Layout><ExecutiveSummary /></Layout>}/>
                    <Route exact path={pageRoutes.pricingRulesHistoryPage} element={<Layout><ProductPricingRuleHistory /></Layout>}/>
                    <Route exact path={pageRoutes.productsPricingSalesPage} element={<Layout><ProductsPricingSales /></Layout>}/>
                    <Route exact path={pageRoutes.productPricingRulePage} element={<Layout><ProductPricingRule /></Layout>}/>
                    <Route exact path={pageRoutes.productsPricingWeightedCpiPage} element={<Layout><ProductsPricingWeightedCpi /></Layout>}/>
                    <Route exact path={pageRoutes.offlineFeedsPage} element={<Layout><Reports /></Layout>}/>
                    <Route exact path={pageRoutes.pricingFeedsPage} element={<Layout><PricingFeeds /></Layout>}/>
                    <Route exact path={pageRoutes.outlierReportsPage} element={<Layout><OutlierReport /></Layout>}/>
                    <Route exact path={pageRoutes.mapviolationReportsPage} element={<Layout><MapViolationReport /></Layout>}/>
                    <Route exact path={pageRoutes.priceChangeReportsPage} element={<Layout><PriceChangeReport /></Layout>}/>
                    <Route exact path={pageRoutes.adminDashboardPage} element={<Layout><AdminDashboard /></Layout>}/>
                    <Route exact path={pageRoutes.tempPage} element={<Layout><temp /></Layout>} />
                    <Route exact path={pageRoutes.errorPage} element={<Layout><ErrorPage /></Layout>} />
                    {isThisFeatureAllowed(WATCHLIST) && <Route exact path={pageRoutes.FavoriteProductPage} element={<Layout><FavoriteProduct /></Layout>}/>}
                    {isThisFeatureAllowed(WATCHLIST) && <Route exact path={pageRoutes.FavoriteBrandPage} element={<Layout><FavoriteBrand /></Layout>} />}
                    <Route exact path={pageRoutes.NormalizedBrands} element={<Layout><NormalizedBrands /></Layout>}/>
                    {isThisFeatureAllowed(WATCHLIST) && <>
                        <Route exact path={pageRoutes.FavoriteProductPage} element={<Layout><FavoriteProduct /></Layout>} />
                        <Route exact path={pageRoutes.FavoriteBrandPage} element={<Layout><FavoriteBrand /></Layout>} />
                    </>}
                    {isThisFeatureAllowed(EDIT) && <Route exact path={pageRoutes.editCategoryPage} element={<Layout><EditCategory /></Layout>} />}
                    {isThisFeatureAllowed(SUPPRESSION) && <>
                        <Route exact path={pageRoutes.productSupressedGapsPage} element={<Layout><ProductGapsSupressed /></Layout>} />
                        <Route exact path={pageRoutes.brandSupressedGapsPage} element={<Layout><BrandGapsSupressed /></Layout>} />
                        <Route exact path={pageRoutes.productOnboardingGapsPage} element={<Layout><ProductOnboarding /></Layout>} />
                        <Route exact path={pageRoutes.brandOnboardingGapsPage} element={<Layout><BrandOnboarding /></Layout>} />
                    </>}
                    {isThisFeatureAllowed(PRICE_LEADER) && <>
                        <Route exact path={pageRoutes.priceLeaderAssortmentPage} element={<Layout><PriceLeaderAssortment /></Layout>} />
                        <Route exact path={pageRoutes.priceLeaderBrandAssortmentPage} element={<Layout><PriceLeaderBrandAssortment /></Layout>}/>
                        <Route exact path={pageRoutes.priceLeaderPricingtPage} element={<Layout><PriceLeaderPricing /></Layout>}/>
                        <Route exact path={pageRoutes.priceLeaderBrandPricingtPage} element={<Layout><PriceLeaderBrandPricing /></Layout>} />
                    </>}
                    {isThisFeatureAllowed(PRICE_GROUP) && <>
                        <Route exact path={pageRoutes.productsGroupPricingPage} element={<Layout><ProductsGroupPricing /></Layout>} />
                    </>}
                    <Route exact path={pageRoutes.tpvrReportsPage} element={<Layout><TpvrReports /></Layout>}/>
                    <Route exact path={pageRoutes.priceComparisonReportsPage} element={<Layout><PriceComparisonReport /></Layout>}/>
                    <Route exact path={pageRoutes.notificationPage} element={<Layout><NotificationsPage /></Layout>}/>
                    <Route path="*" element={<Login />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default Routess;