import React, {useEffect, useState, lazy,useContext} from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addParamsToLink, isThisFeatureAllowed } from "../common";
import EventCommonFilter from "../../../contextApi/EventCommonFilter";
import Select from 'react-select';
import {
  faHome,
  faPowerOff,
  faLink,
  faList,
  faFileSignature,
  faBug,
  faCubes,
  faDollarSign,
  faChartPie,
  faSearchDollar,
  faBell,
  faQuestionCircle,
  faUser,
  faExclamationTriangle,
  faMapMarkerAlt,
  faFilePowerpoint,
  faMapMarkedAlt,
  faColumns,
  faFileCsv,
  faClipboardList,
  faFileInvoiceDollar,
  faClipboardCheck,
  faEyeSlash,
  faSackDollar,
  faLightbulb,
  faFileLines
} from "@fortawesome/free-solid-svg-icons";
import * as pageRoutes from "../../../pageRoutes";
import { getLocalStorageValue, removeValueFromLocalStorage , setLocalStorageValue } from "../../../utils/localStorageOperations";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { SUGGESTIONS, ZIPCODES, isDemoEnv, APPROVAL_SUMMARY, OFFLINE_FEEDS, PRICING_FEEDS, MATCH_COVERAGE, MATCH_OPPORTUNITIES, OUTLIER_REPORT, TPVR_REPORT, EXECUTIVE_SUMMARY, ZONES, HISTORICAL_PRICING, MAP_VIOLATION_REPORT } from "../constants";
import config from "../../../config/config.js";

import "./sideBarMenu.scss";
import { PRICE_LEADER } from "../constants";
import Notification from "../Notifications/Notifications";
import ZipcodeConfig from "../ZipcodeConfig/ZipcodeConfig.js";
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter.js";
import SideMenuCommonFilterLib from "../../../contextApi/SideMenuCommonFilterLib.js";
import HowToUserGuide from "./HowToUserGuide.js";

const ReportIssue = lazy(() => import("../../ReportIssue/ReportIssue"));

const SideBarMenu = (props) => {
  let location = useLocation();

  let navigationConfig = {
    "Matches Module": ["productsPricing"],
    "Assortment" : ["dashboard", "productGaps", "productGapsSupressed", "uniqueByRetailersGaps", "brandGaps", "brandGapsSupressed", "priceLeaderAssortment", "priceLeaderBrandAssortment"],
    "Pricing": ["dashboardPricing", "priceLeaderPricing", "priceLeaderBrandPricing", "productsPricing", "compProductsPricing", "productsPricingRule"],
    "Reports": ["matches", "matchReportsTpvr", "matchReportsTpvrManager", "matchReports", "matchReportsSla", "matchCoverage", "matchCoverageCategory", "matchCoverageRetailer", "matchCoveragePriority", "pricing", "pricingSummary", "assortmentWow"]
  };
  let childNavigationConfig = {
    "Matches": ["matches", "matchReportsTpvr", "matchReportsTpvrManager", "matchReports", "matchReportsSla", "matchCoverage", "matchCoverageCategory", "matchCoverageRetailer", "matchCoveragePriority"],
    "Pricing": ["pricing", "pricingSummary"]
  };
  const [reprotIssueShow, setReprotIssueShow] = useState(false);
  const [zipcodeConfigShow, setZipcodeConfigShow] = useState(false);
  const [parentNav, setParentNav] = useState("");
  const [childNav, setChildNav] = useState("");
  const [eventCommonFilterContext, setEventCommonFilterLibContext] = useContext(EventCommonFilter);
  const [matchModuleActive, setMatchModuleActive] = useState(false);
  const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
  const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
  const isActive = (paths) => paths.includes(location.pathname);
  let modulesAccess = [];
  let tenant_config = [];
  let reportsConfig = {};
  let currentLocationDefault = {}
  const tenantCode = localStorage.getItem("tenant");
  let demoConfig = {
    "Assortment": false,
    "Pricing": false
  };
  if (isDemoEnv) {
    demoConfig["Assortment"] = true;
    demoConfig["Pricing"] = true;
    // This hardcoded tenant logic should be replaced by environment driven config 
    if (tenantCode != "pet" && tenantCode != "grocery") {
      demoConfig["Pricing"] = false;
    }
    if (tenantCode == "bjs") {
      demoConfig["Assortment"] = false;
    }
  }

  if (localStorage.getItem('modules')) {
    modulesAccess = localStorage.getItem('modules').split(',');
  }
  if(localStorage.getItem('tenantFeatureConfig')){
    tenant_config = JSON.parse(localStorage.getItem('tenantFeatureConfig'))
  }
  if (localStorage.getItem('reports')) {
    reportsConfig = JSON.parse(localStorage.getItem('reports'))
  }
  let passThroughAccess = JSON.parse(localStorage.getItem('passThrough'))

  let data = []
  if(passThroughAccess){
    data = JSON.parse(localStorage.getItem('tenantLocations'))
    let currentLocationValue = localStorage.getItem('current_location')
    for(let index=0;index<data.length;index++){
        for(let key in data[index]){
          if(data[index][key]===currentLocationValue){
            currentLocationDefault = data[index]
            break;
          }
        }
      }
  }
  
  const eventFilterUpdate = (state) => {
    let newFilterBtnsSelected = {
        'event': state.event
    };
    setEventCommonFilterLibContext(newFilterBtnsSelected);
};

  useEffect(() => {
    //console.log('location', location.pathname);
    let navigationParent = "";
    let navigationChild = "";
    Object.keys(navigationConfig).map(navigationKey => {
      let pathNameArr = location.pathname.split('/').filter(pathnameSingle => {
        return pathnameSingle.length;
      });
      if(pathNameArr.length && navigationConfig[navigationKey].includes(pathNameArr[0])) {
        navigationParent = navigationKey;
      }
    });
    Object.keys(childNavigationConfig).map(navigationKey => {
      let pathNameArr = location.pathname.split('/').filter(pathnameSingle => {
        return pathnameSingle.length;
      });
      if(pathNameArr.length && childNavigationConfig[navigationKey].includes(pathNameArr[0])) {
        navigationChild = navigationKey;
      }
    });
    setParentNav(navigationParent);
    setChildNav(navigationChild);
  }, [location]);

  const redirectToPmt = () => {
    logoutAccount()
    window.location.href = config.pmtEndPoint
  }

  const logoutAccount = () => {
    localStorage.clear()
    if(passThroughAccess){
        window.location.href = config.pmtEndSignInEndPoint;
    }
  };

  const onChangeFilterOptions = (selectedOption)=>{
    setLocalStorageValue("tenant",selectedOption["value"]);
    setLocalStorageValue("current_location",selectedOption["label"])
    window.location.reload()
  }

  const getSelectedZipcodes = () => {
    let zones = [];
    let zipcodes = [];
    let countryCode = localStorage.getItem('countryCode');
    let selectedZipcodes = Array.from(sideMenuCommonFilterContext.zipcodes);
    if (sideMenuCommonFilterLibContext?.tenant_zipcode) {
      sideMenuCommonFilterLibContext.tenant_zipcode.map(tenant_zipcode_single => {
        if (selectedZipcodes.includes(tenant_zipcode_single.zipcode)) {
          if (!zipcodes.includes(tenant_zipcode_single.zipcode)) {
            zipcodes.push(tenant_zipcode_single.zipcode);
          }
          if (!zones.includes(tenant_zipcode_single.zone)) {
            zones.push(tenant_zipcode_single.zone);
          }
        }
      });
    }
    if (sideMenuCommonFilterLibContext?.comp_zipcode) {
      sideMenuCommonFilterLibContext.comp_zipcode.map((tenant_zipcode_single, tenant_zipcode_single_no) => {
        if (selectedZipcodes.includes(tenant_zipcode_single.zipcode)) {
          if (!zipcodes.includes(tenant_zipcode_single.zipcode)) {
            zipcodes.push(tenant_zipcode_single.zipcode);
          }
          if (!zones.includes(tenant_zipcode_single.zone)) {
            zones.push(tenant_zipcode_single.zone);
          }
        }
      });
    }
    /*return (<>
      <span style={{fontSize: '0.85rem'}}>US</span>
      <span className="ms-1 me-1">&#11208;</span>
      <span style={{fontSize: '0.85rem'}}>WEST</span><br/>
      <span className="fw-bold">07107</span>
    </>)*/
    return (<>
      {countryCode ? <span style={{fontSize: '1.25rem'}} title={zipcodes.length ? `Zones: ${zones.join(', ')} | Zipcodes: ${zipcodes.join(', ')}` : 'Select Locations'}>{countryCode} <span className="ms-1 fs-5">{zipcodes.length ? <FontAwesomeIcon  icon={faMapMarkedAlt} /> : <FontAwesomeIcon icon={faMapMarkerAlt} />}</span></span> : <></>}
    </>);
  };

  const popover = (
    <Popover id="popover-basic" style={{'max-width':'500px'}}>
      <Popover.Header as="h3">Coming soon!</Popover.Header>
      <Popover.Body>
        1. Price suggestions generation immediately after new rule creation.<br/>
        2. Sales trend to showcase the impact of rule driven price changes.<br/>
        3. Elasticity view to represent price changes impact.<br/>
        4. Enhanced widgets for rule driven pricing suggestions on Dashboard view.<br/>
        5. UI betterments.
      </Popover.Body>
    </Popover>
  );

  const popoverPriceRule = (
    <Popover id="popover-basic" style={{'max-width':'500px'}}>
      <Popover.Header as="h3">Pricing rules Disclaimer</Popover.Header>
      <Popover.Body>
        Pricing rules driven suggestions and Approval workflow is currently in beta version. While we strive to provide a reliable and feature-rich product, it's important to note that beta versions may undergo updates, modifications, and improvements based on user feedback and product development. We appreciate your understanding and patience as we work towards refining and enhancing Pricing engine. Your feedback is valuable in helping us shape the future of our product. Please feel free to provide any feedback or report any issues you encounter during the beta testing phase.
      </Popover.Body>
    </Popover>
  );

  const popoverUAT = (
    <Popover id="popover-basic" style={{'max-width':'500px'}}>
      <Popover.Header as="h3">User Acceptance Testing</Popover.Header>
      <Popover.Body>
        UAT Phase! Your feedback is invaluable in shaping our product. Thank you for participating.
      </Popover.Body>
    </Popover>
  );

  const eventFitlerUpdate = (state) => {
    let newFilterBtnsSelected = {
        'event': state.event
    };
    setEventCommonFilterLibContext(newFilterBtnsSelected);
    setMatchModuleActive(true);
  };


  return (<Navbar style = {{ backgroundColor : "#F5F4ED"}} className="bungee-nav">

    <Container fluid>

      <NavLink title="Bungee Retailscape" className="navbar-tenant-img" to={pageRoutes.loginPage}>
        <img 
          height="48"
          className="mx-auto"
          src={localStorage.getItem('tenantLogoUrl')} 
          />
      </NavLink>
      <NavLink title="Bungee Retailscape" className="navbar-brand-img navbar-brand" to={pageRoutes.loginPage}>
        <img 
          height="26"
          className="mx-auto"
          src={process.env.PUBLIC_URL + "/images/bungee-logo-mini.svg"} 
          />
      </NavLink>

      <Nav className="me-auto border-start border-end ps-2 main-nav">
        {modulesAccess.includes("Assortment") 
          ? <NavDropdown className={(parentNav == "Assortment") 
            ? "menu-items active" 
            : "menu-items"} title="Assortment">
          <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.dashboardPage)}><FontAwesomeIcon icon={faChartPie} /> Dashboard</NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.productGapsPage)}><FontAwesomeIcon icon={faList}/> Product Gaps</NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.brandGapsPage)}><FontAwesomeIcon icon={faFileSignature}/> Brand Gaps</NavDropdown.Item>
          {isThisFeatureAllowed(PRICE_LEADER) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.priceLeaderAssortmentPage)}><FontAwesomeIcon icon={faDollarSign}/> Price Leader</NavDropdown.Item> : null}
        </NavDropdown> 
      : ""}

        {modulesAccess.includes("Pricing") ? <NavDropdown className={(parentNav == "Pricing") ? "menu-items active" : "menu-items"} title="Pricing">
          <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.dashboardPricingPage)}><FontAwesomeIcon icon={faHome}/> Dashboard</NavDropdown.Item>
          <NavDropdown.Item as={NavLink} onClick={() => eventFilterUpdate({ event: { "name": "products_list", "data": { "products_type": 'base' }}})} to={addParamsToLink(pageRoutes.productsPricingPage)}><FontAwesomeIcon icon={faClipboardList}/> Products</NavDropdown.Item>
          {isThisFeatureAllowed(PRICE_LEADER) && <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.priceLeaderPricingtPage)}><FontAwesomeIcon icon={faSearchDollar}/> Price Leader</NavDropdown.Item>}
          {isThisFeatureAllowed(SUGGESTIONS) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.pricingRulesGroupListPage)}><FontAwesomeIcon icon={faSearchDollar}/> Pricing Rules</NavDropdown.Item> : ""}
          {isThisFeatureAllowed(SUGGESTIONS) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.pricingProductSuppressionsListPage)} active={isActive([pageRoutes.pricingProductSuppressionsListPage])}><FontAwesomeIcon icon={faEyeSlash}/> Pricing Suppressions</NavDropdown.Item> : ""}
        </NavDropdown> : ""}
      
      {modulesAccess.includes("normalized_brand") ? <NavDropdown className={(parentNav == "Brand Dictionary") ? "menu-items active" : "menu-items"} title="Brand Dictionary">
        <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.NormalizedBrands)}><FontAwesomeIcon icon={faHome}/> Dashboard</NavDropdown.Item>
      </NavDropdown> : ""}

        {/*(modulesAccess.includes("Reports") || isThisFeatureAllowed(MATCH_COVERAGE) || isThisFeatureAllowed(MATCH_OPPORTUNITIES)) ? <NavDropdown className={(parentNav == "Reports") ? "menu-items active" : "menu-items"} title="Reports"> */}
        { true ? <NavDropdown className={(parentNav == "Reports") ? "menu-items active" : "menu-items"} title="Reports"> 
            {/*((reportsConfig[OFFLINE_FEEDS] && reportsConfig[OFFLINE_FEEDS].active) || (reportsConfig[PRICING_FEEDS] && reportsConfig[PRICING_FEEDS].active) || isThisFeatureAllowed(EXECUTIVE_SUMMARY) || isThisFeatureAllowed(HISTORICAL_PRICING) || (reportsConfig[OUTLIER_REPORT] && reportsConfig[OUTLIER_REPORT].active)) ? <NavDropdown.Item>*/}
            {true ? <NavDropdown.Item>
              <FontAwesomeIcon icon={faSackDollar}/> Pricing
              <div className="dropdown-menu dropdown-submenu">
                {reportsConfig[OFFLINE_FEEDS] && reportsConfig[OFFLINE_FEEDS].active ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.offlineFeedsPage)}>Price Recommendations</NavDropdown.Item> : ""}
                {reportsConfig[PRICING_FEEDS] && reportsConfig[PRICING_FEEDS].active ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.pricingFeedsPage)}>Price Feeds</NavDropdown.Item> : ""}
                {isThisFeatureAllowed(EXECUTIVE_SUMMARY) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.executiveSummaryReportsPage)}>Executive Summary</NavDropdown.Item> : ""}
                {true ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.priceComparisonReportsPage)}>Price Comparison</NavDropdown.Item> : ""}
                {reportsConfig[OUTLIER_REPORT] && reportsConfig[OUTLIER_REPORT].active ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.outlierReportsPage)}>Outlier Summary</NavDropdown.Item> : ""}
                {reportsConfig[MAP_VIOLATION_REPORT] && reportsConfig[MAP_VIOLATION_REPORT].active ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.mapviolationReportsPage)}>Map Violation Summary</NavDropdown.Item> : ""}
              </div>
            </NavDropdown.Item> : <></>}

            {(isThisFeatureAllowed(MATCH_COVERAGE) || isThisFeatureAllowed(MATCH_OPPORTUNITIES) || isThisFeatureAllowed(TPVR_REPORT)) ? <NavDropdown.Item>
              <FontAwesomeIcon icon={faClipboardCheck}/> Matches
              <div className="dropdown-menu dropdown-submenu">
                {isThisFeatureAllowed(MATCH_COVERAGE) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.matchCoveragePage)}>Match Coverage</NavDropdown.Item> : ""}
                {isThisFeatureAllowed(MATCH_OPPORTUNITIES) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.matchOpportunitiesPage)}>Match Opportunities</NavDropdown.Item> : ""}
                {isThisFeatureAllowed(TPVR_REPORT) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.tpvrReportsPage)}>TPVR Summary</NavDropdown.Item> : ""}
              </div>
            </NavDropdown.Item> : <></>}

            {(isThisFeatureAllowed(SUGGESTIONS)) ? <NavDropdown.Item>
              <FontAwesomeIcon icon={faFileInvoiceDollar}/> Price rules
              <div className="dropdown-menu dropdown-submenu">
                {isThisFeatureAllowed(SUGGESTIONS) ? <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.priceChangeReportsPage)}>Price Changes</NavDropdown.Item> : ""}
                {isThisFeatureAllowed(SUGGESTIONS) ? <NavDropdown.Item as={NavLink} onClick={() => eventFilterUpdate({ event: { "name": "", "data": { "group_name": ''}}})} to={addParamsToLink(pageRoutes.suggestionsReportsPage)}>Multi Suggestions</NavDropdown.Item> : ""}
              </div>
            </NavDropdown.Item> : <></>}

        </NavDropdown> : ""}
    </Nav>

    {(demoConfig["Assortment"] && parentNav == "Assortment") ? <div className="alert-container" style = {{paddingTop: 11, paddingRight: 33, display: 'contents'}}>
      <span className="badge bg-light border-end" style={{fontStyle:"bold",fontSize:"13px", color:"#666666"}}>Disclaimer - Assortment Module contains sample Data for Representation Purpose only</span>
    </div> : ''}
      
    {(demoConfig["Pricing"] && parentNav == "Pricing") ? <div className="alert-container" style = {{paddingTop: 11, paddingRight: 33, display: 'contents'}}>
      <span className="badge bg-light border-end" style={{fontStyle:"bold",fontSize:"13px", color:"#666666"}}>Disclaimer - Pricing Module contains sample Data for Representation Purpose only</span>
    </div> : ''}

    {(isThisFeatureAllowed(ZIPCODES) && !isThisFeatureAllowed(ZONES)) ? <a title="Set Zone" className="nav-link cursor-pointer text-dark d-flex justify-content-center align-items-center border-start border-end me-3" onClick={() => setZipcodeConfigShow(true)}>
      <div className="float-start">
        <div style={{lineHeight: '100%'}}>
            {getSelectedZipcodes()}
          </div>
      </div>
    </a> : ""}

    {/*<OverlayTrigger trigger="click" placement="left" overlay={popoverPriceRule}>
      <a className="cursor-pointer me-2 text-warning">
        <div 
          className="badge rounded-pill bg-warning text-dark" 
          style={{
            lineHeight: "12px",
            fontSize: "11px"
          }}
        >Beta Version</div>
      </a>
        </OverlayTrigger>*/}

    <Nav className="right-nav">

      {/*<a 
        className="nav-link cursor-pointer active" 
        title="Pricing Rules User Guide" 
        href={process.env.PUBLIC_URL + "/user-guide-price-rule-suggestions-feature-v1.pdf"} 
        target="_blank" 
      >
        <FontAwesomeIcon icon={faFilePowerpoint}/>
      </a>*/}

      {/* <OverlayTrigger trigger="click" placement="left" overlay={popover}>
        <a className="nav-link cursor-pointer active">
          <FontAwesomeIcon icon={faBell}/>
        </a>
      </OverlayTrigger> */}

      {/*('allowedFeatures' in tenant_config && tenant_config['allowedFeatures'].includes('location') && passThroughAccess) ? 
        <Select
          menuPortalTarget={document.body}
          isSearchable={false}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          options={data || []}
          placeholder="Select Location..."
          defaultValue={currentLocationDefault}
          onChange={onChangeFilterOptions}
      />
        : null
    */}

      {(passThroughAccess) ? <a title="Bungee PMT" className="navbar-brand-img navbar-brand" onClick={redirectToPmt} style = {{ color : "white"}}>
        <img 
          height="27"
          className="mt-3 cursor-pointer"
          src={process.env.PUBLIC_URL + "/images/PMT.png"} 
          />
      </a>
      : null}
      {<Notification notificationClick={props.notificationClick} setNewNotification={props.setNewNotification} newNotification={props.newNotification}/>}

      {<a title="Report an Issue" className="nav-link cursor-pointer active" onClick={() => setReprotIssueShow(true)}><FontAwesomeIcon icon={faBug} /></a>}
    
      {/*modulesAccess.includes("UserGuide") ? <NavLink  style = {{ color : "white"}} className="nav-link cursor-pointer" as={NavLink} to={addParamsToLink(pageRoutes.userGuidePage)} >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </NavLink> : ""*/}
      
      <HowToUserGuide />
    
      {passThroughAccess ? <a className="nav-link active" onClick={logoutAccount}><FontAwesomeIcon icon={faPowerOff} /></a> 
        : <NavLink  className="nav-link active" to={pageRoutes.loginPage} onClick={logoutAccount}><FontAwesomeIcon icon={faPowerOff} /></NavLink> 
      }

    </Nav>
  </Container>
  
  {reprotIssueShow && <ReportIssue showForm={reprotIssueShow} setReprotIssueShow={setReprotIssueShow} />}
  
  {zipcodeConfigShow && <ZipcodeConfig showForm={zipcodeConfigShow} setReprotIssueShow={setZipcodeConfigShow} />}

</Navbar>);
};

export default SideBarMenu;
