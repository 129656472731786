import React, { useEffect, useState } from "react";
import MainHeader from "../MainHeader";
import SideBarMenu from "../SideBarMenu";
import { isDemoEnv } from "../constants";
//utils
import {
  getQueryParams,
  addParamstoPage,
  removeParamstoPage,
} from "../../../utils/routeBasedOperations";
// context api
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter";
import SideMenuCommonFilterLib  from "../../../contextApi/SideMenuCommonFilterLib";
import SideMenuCommonFilterDefault  from "../../../contextApi/SideMenuCommonFilterDefault";
import BreadCrumbsRoutes from "../../../contextApi/BreadCrumbsRoutes";
import ProductGapSubFilter from "../../../contextApi/ProductGapSubFilter";
import BrandGapSubFilter from "../../../contextApi/BrandGapSubFilter";
import TopMenuBar from "../../../contextApi/TopMenuBar";
import UserPersonalizedFilterContext from "../../../contextApi/UserPersonalizedFilterContext.js";

import "./layout.scss";
import EventCommonFilter from "../../../contextApi/EventCommonFilter";
import { UpdateFiltersWithFavoriteFilters } from "../common";
import Footer from "./Footer.js";
import { getLocalStorageValue } from "../../../utils/localStorageOperations";


// const brand = getQueryParams("keyword");
const country = getQueryParams("country");
const brand = getQueryParams("brand");
const tenant_segments = JSON.parse(getLocalStorageValue("tenant_segments")) || [];

//const favoriteFilter = UpdateFiltersWithFavoriteFilters();
const favoriteFilter = {};

const contextApiDefaultValue = {
  retailer: favoriteFilter?.filterConfigValues?.retailer || new Set([]),
  category: favoriteFilter?.filterConfigValues?.category || new Set([]),
  brand: favoriteFilter?.filterConfigValues?.brand || new Set([]),
  best_seller:  favoriteFilter?.filterConfigValues?.best_seller || new Set(["ALL"]),
  brand_gap: favoriteFilter?.filterConfigValues?.brand_Gap || new Set(["ALL"]),
  private_label: favoriteFilter?.filterConfigValues?.private_label || new Set(["Exclude Private Labels"]),
  image_competitor:  favoriteFilter?.filterConfigValues?.image_competitor || new Set(["ALL"]),
  assortment_score: new Set([0,100]),
  economic_performance_score: new Set([0,100]),
  customer_value_score: new Set([0,100]),
  catalog_strategy_score: new Set([0,100]),
  rating: favoriteFilter?.filterConfigValues?.rating || new Set([0,5]),
  review_count: favoriteFilter?.filterConfigValues?.review_count || new Set([0,999999]),
  third_party: favoriteFilter?.filterConfigValues?.third_party || new Set(["ALL"]),
  subcategory: favoriteFilter?.filterConfigValues?.subcategory || new Set([]),
  subsubcategory: favoriteFilter?.filterConfigValues?.subsubcategory || new Set([]),
  subsubsubcategory: favoriteFilter?.filterConfigValues?.subsubsubcategory || new Set([]),
  tenant_priority: favoriteFilter?.filterConfigValues?.tenant_priority || new Set(["ALL"]),
  price_lagger: favoriteFilter?.filterConfigValues?.price_lagger || new Set([]),
  postalCode : favoriteFilter?.filterConfigValues?.postalCode || new Set([]),
  comp_stock_status_flag: new Set(['Yes']),
  tenant_stock_status_flag:favoriteFilter?.filterConfigValues?.tenant_stock_status_flag || new Set(['Yes']),
  price_relativity:favoriteFilter?.filterConfigValues?.price_relativity || new Set([]),
  custom_config: favoriteFilter?.filterConfigValues?.custom_config || new Set([false]),
  zipcodes: favoriteFilter?.filterConfigValues?.zipcodes || new Set([]),
  tenant_zipcode: favoriteFilter?.filterConfigValues?.tenant_zipcode || new Set([]),
  comp_zipcode: favoriteFilter?.filterConfigValues?.tenant_zipcode || new Set([]),
  zone: favoriteFilter?.filterConfigValues?.zone || new Set([]),
  user: new Set([]),
  report_category: new Set([]),
  report_subcategory: new Set([]),
  report_subsubcategory: new Set([]),
  report_subsubsubcategory: new Set([]),
  report_brand: new Set([]),
  report_priority: new Set([]),
  report_comp_source_store: new Set([]),
  is_cpi_outlier: new Set(['N']),
  map_violation: new Set(['N']),
  price: new Set(['effective_price']),
  report_comparison_period: new Set(['Monthly']),
  tenant_segment: tenant_segments.length > 0 ? new Set([tenant_segments[0]]) : new Set([]),
  report_cpi: new Set(['ALL']),
};

export const contextApiDefaultValueOnClear = {
  retailer: new Set([]),
  category: new Set([]),
  brand: new Set([]),
  best_seller:  new Set(["ALL"]),
  brand_gap: new Set(["ALL"]),
  private_label: new Set(["Exclude Private Labels"]),
  image_competitor:  new Set(["ALL"]),
  assortment_score: new Set([0,100]),
  economic_performance_score: new Set([0,100]),
  customer_value_score: new Set([0,100]),
  catalog_strategy_score: new Set([0,100]),
  rating: new Set([0,5]),
  review_count: new Set([0,999999]),
  third_party: new Set(["ALL"]),
  subcategory: new Set([]),
  subsubcategory: new Set([]),
  subsubsubcategory: new Set([]),
  tenant_priority: new Set(["ALL"]),
  price_lagger: new Set([]),
  postalCode :new Set([]),
  comp_stock_status_flag: new Set(['Yes']),
  tenant_stock_status_flag:new Set(['Yes']),
  price_relativity:new Set([]),
  custom_config: new Set([false]),
  zipcodes:new Set([]),
  tenant_zipcode: new Set([]),
  comp_zipcode: new Set([]),
  zone: new Set([]),
  user: new Set([]),
  report_category: new Set([]),
  report_subcategory: new Set([]),
  report_subsubcategory: new Set([]),
  report_subsubsubcategory: new Set([]),
  report_brand: new Set([]),
  report_priority: new Set([]),
  report_comp_source_store: new Set([]),
  is_cpi_outlier: new Set(['N']),
  price: new Set(['effective_price']),
  map_violation: new Set(['N']),
  report_comparison_period: new Set(['Monthly']),
  tenant_segment: tenant_segments.length > 0 ? new Set([tenant_segments[0]]) : new Set([]),
  report_cpi: new Set(['ALL']),
};
const contextApiDefaultLibValue = {
  retailers: [],
  imageRetailers: [],
  categories: [],
  brands: [],
};

const Layout = ({ children }) => {
  const [
    sideMenuCommonFilterContext,
    setSideMenuCommonFilterContext,
  ] = useState(contextApiDefaultValue);

  const [
    sideMenuCommonFilterDefaultContext,
    setSideMenuCommonFilterDefaultContext,
  ] = useState(contextApiDefaultValueOnClear);

  const [
    sideMenuCommonFilterLibContext,
    setSideMenuCommonFilterLibContext,
  ] = useState(contextApiDefaultLibValue);

  const [
    eventCommonFilterContext,
    eventMenuCommonFilterLibContext,
  ] = useState({});

  const stateUpdateFromContext = (state) => {
    let removeParams = ['kpi'];
    if (state?.retailer) {
      const retailerSelection = Array.from(state.retailer);
      if (retailerSelection.length === 0) {
        removeParams.push('retailer');
      } /*else {
        addParamstoPage({ retailer: retailerSelection });
      }*/
    }
    if (state?.category) {
      const categorySelection = Array.from(state.category);
      if (categorySelection.length === 0) {
        removeParams.push('category');
      } /*else {
        addParamstoPage({ category: categorySelection}); 
      }*/
    }
    removeParamstoPage(removeParams);
    setSideMenuCommonFilterContext(state);
  };

  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  const [productGapSubFilterText, setProductGapSubFilterText] = useState({
    finalSubFilterText: "",
    subFilterOption: "",
    subFilterText: "",
  });
  const [brandGapSubFilterText, setBrandGapSubFilterText] = useState({
    finalSubFilterText: "",
    subFilterOption: "all",
    subFilterText: "",
  });

  const [UserPersonalizedFilterValue, setUserPersonalizedFilterValue] = useState({
    label: favoriteFilter?.filterConfigName,
    value: favoriteFilter?.filterConfigId,
    isFavorite: favoriteFilter?.isFavorite,
    allSavedFilter: [],
  });

  const [userPersonalizedFilterListValue, setUserPersonalizedFilterListValue] = useState([]);
  // fixMenuToOpen  - decides fixation of Menu
  // hideMenu - works for on hover events for menu while collapsed
  const [overlayMenu, setOverlayMenu] = useState(false);
  const [fixMenuToOpen, setFixMenuToOpen] = useState(false);
  const [fixTopMenuToOpen, setFixTopMenuToOpen] = useState(true);

  const menuShowHideHandler = () => {
    setFixMenuToOpen(!fixMenuToOpen);
  };
  const arrowIconForToggle = !fixMenuToOpen
    ? "/images/icons/common/double_arrow_right.svg"
    : "/images/icons/common/double_arrow_left.svg";

  const mouseMoveEventHandler = (e) => {
    if (e.clientX === 0) {
      setOverlayMenu(true);
    }
  };

  const mouseOutEventHandler = (e) => {
    if (!e.target.closest("#sidebar-menu")) {
      setOverlayMenu(false);
    }
  };

  const [ newNotification, setNewNotification ] = useState(0);
  const [ rescanCompletedSkus, setRescanCompletedSkus ] = useState([]);
  const [ notificationClick, setNotificationClick ] = useState({});

  useEffect(() => {
    //window.addEventListener("mouseover", mouseMoveEventHandler);
    //window.addEventListener("mouseout", mouseOutEventHandler);
    return () => {
      //window.removeEventListener("mouseover", mouseMoveEventHandler);
      //window.addEventListener("mouseout", mouseOutEventHandler);
    };
  }, []);

  return (
    <BreadCrumbsRoutes.Provider value={[BreadCrumbs, setBreadCrumbs]}>
      <SideMenuCommonFilter.Provider
        value={[sideMenuCommonFilterContext, stateUpdateFromContext]}
      >
        <SideMenuCommonFilterDefault.Provider
          value={[sideMenuCommonFilterDefaultContext, setSideMenuCommonFilterDefaultContext]}
        >
          <SideMenuCommonFilterLib.Provider
            value={[sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext]}
          >
            <EventCommonFilter.Provider
              value={[eventCommonFilterContext, eventMenuCommonFilterLibContext]}
            >
              <ProductGapSubFilter.Provider
                value={[productGapSubFilterText, setProductGapSubFilterText]}
              >
                <BrandGapSubFilter.Provider
                  value={[brandGapSubFilterText, setBrandGapSubFilterText]}
                >
                  <TopMenuBar.Provider
                    value={[fixTopMenuToOpen, setFixTopMenuToOpen]}
                  >
                    <UserPersonalizedFilterContext.Provider
                      value={[UserPersonalizedFilterValue, setUserPersonalizedFilterValue]}
                    >
                      {/*<MainHeader />*/}
                      <div id="page-layout">
                        <SideBarMenu />
                        {/*<SideBarMenu
                          className={
                            (!fixMenuToOpen ? "hide-menu" : "show-menu") +
                            (overlayMenu ? " overlay-menu" : "")
                          }
                          collapsedMenu={!fixMenuToOpen && !overlayMenu}
                        />
                        <img
                          src={arrowIconForToggle}
                          className={
                            (!fixMenuToOpen ? "close" : "") +
                            (!fixMenuToOpen && overlayMenu ? "close" : "") +
                            " menu-show-hide-icon mw-20px"
                          }
                          onClick={menuShowHideHandler}
                        />*/}
                        {children}
                        <Footer />
                      </div>
                    </UserPersonalizedFilterContext.Provider>
                  </TopMenuBar.Provider>
                </BrandGapSubFilter.Provider>
              </ProductGapSubFilter.Provider>
            </EventCommonFilter.Provider>
          </SideMenuCommonFilterLib.Provider>
        </SideMenuCommonFilterDefault.Provider>
      </SideMenuCommonFilter.Provider>
    </BreadCrumbsRoutes.Provider>
  );
};

export default Layout;
