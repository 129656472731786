import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Select, { StylesConfig, components } from 'react-select';
import SideMenuCommonFilterLib from "../../../contextApi/SideMenuCommonFilterLib";
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter";
import { useNavigate } from "react-router";
import * as pageRoutes from "../../../pageRoutes";

const ZipcodeConfig = (props) => {
    
    const {
        showForm,
        setReprotIssueShow,
    } = props;

    const navigate = useNavigate();

    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [filterBtnsSelected, setFilterBtnsSelected] = useState(sideMenuCommonFilterContext);
    const [loading, setLoading] = useState(false);
    const [zipcodeObjs, setZipcodeObjs] = useState({});
    const [allZipcodes, setAllZipcodes] = useState([]);
    const [selectedZipCodes, setSelectedZipCodes] = useState([...filterBtnsSelected.zipcodes]);
    const [isZipCodeMenuOpen, setIsZipCodeMenuOpen] = useState(false);
    const [isZoneMenuOpen, setIsZoneMenuOpen] = useState(false);


    useEffect(() => {
        if(showForm) {
            resetForm();
        }
        console.log('sideMenuCommonFilterLibContext', sideMenuCommonFilterLibContext);
    }, [showForm]);

    useEffect(() => {
        convertZipcodes();
    }, [sideMenuCommonFilterLibContext]);

    const handleClose = () => setReprotIssueShow(false);

    const resetForm = () => {};

    const styles: StylesConfig<ColourOption, true> = {
        control: (base, state) => ({
            ...base,
            "border-top": "0",
            "border-left": "0",
            "border-right": "0",
            "border-radius": "0",
            boxShadow: state.isFocused ? 0 : 0,
        }),
        option: (base, {isFocused, isSelected, isMulti}) => ({
            ...base,
            color: isFocused
                ? '#000'
                : isSelected
                    ? '#000'
                    : undefined,
            background: isFocused
                ? 'rgba(0, 0, 0, 0.08)'
                : isSelected
                    ? 'rgba(0, 0, 0, 0.07)'
                    : undefined,
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            ":before": {
                content: isMulti 
                    ?  isSelected
                        ? '"☑ "' 
                        : '"☐ "' 
                    : '""'
            }
        }),
        singleValue: (base, state) => ({
            ...base,
            "color": "#316CF4"
        }),
        multiValue: (base, state) => ({
            ...base,
            "color": "#316CF4",
            "background-color": "#fff",
            "border": "1px solid #c0c0c0",
            "border-radius": "12px",
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        //menu: provided => ({ ...provided, zIndex: 9999 }),
        multiValueRemove: (base, state) => ({
            ...base,
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                "border-top-right-radius": "12px",
                "border-bottom-right-radius": "12px",
            },
        }),
        multiValueLabel: (styles: any) => ({
            ...styles,
            whiteSpace: "normal",
            "color": "#316CF4",
        }),
    };

    const convertZipcodes = () => {
        let contries = [];
        let zones = [];
        let zipcodes = {};
        let allZipcodes = [];
        let allZipcodeValues = [];
        if (sideMenuCommonFilterLibContext.comp_zipcode) {
            allZipcodes = allZipcodes.concat(sideMenuCommonFilterLibContext.comp_zipcode);
        }
        if (sideMenuCommonFilterLibContext.tenant_zipcode) {
            allZipcodes = allZipcodes.concat(sideMenuCommonFilterLibContext.tenant_zipcode);
        }
        
        allZipcodes.map(zipcodeObj => {
            if (!contries.includes(zipcodeObj.country_code)) {
                contries.push(zipcodeObj.country_code);
            }
            let zone = `${zipcodeObj.country_code} - ${zipcodeObj.zone}`; 
            if (!contries.includes(zone)) {
                zones.push(zone);
            }
            if (!(zone in zipcodes)) {
                zipcodes[zone] = [];
            }
            zipcodes[zone].push(zipcodeObj.zipcode);
            allZipcodeValues.push(zipcodeObj.zipcode);
        });
        setZipcodeObjs(zipcodes);
        setAllZipcodes(allZipcodeValues);
        console.log('contries', contries, zones, zipcodes);
    };

    const selectedZonValues = () => {
        let zonValues = [];
        Object.keys(zipcodeObjs).map(zipcodeObj => {
            let objVals = zipcodeObjs[zipcodeObj];
            if (objVals.some(item => selectedZipCodes.includes(item))) {
                zonValues.push({
                    label: zipcodeObj,
                    value: zipcodeObj
                });
            }
        });
        return zonValues;
    };

    const setFilters = () => {
        let newSet = { ...filterBtnsSelected};
        let newSideMenuCommonFilterContext  = {...sideMenuCommonFilterContext};
        let tenant_zipcode = sideMenuCommonFilterLibContext.tenant_zipcode.filter(zipcode => {
            return selectedZipCodes.includes(zipcode.zipcode);
        }).map(zipcode => {
            return zipcode.zipcode;
        });
        let comp_zipcode = sideMenuCommonFilterLibContext.comp_zipcode.filter(zipcode => {
            return selectedZipCodes.includes(zipcode.zipcode);
        }).map(zipcode => {
            return zipcode.zipcode;
        });
        let zones = selectedZonValues().map(singleZone => {
            let locationName = singleZone.value.split(' - ');
            locationName.shift();
            return locationName.join(' - ');
        });

        newSet['zipcodes'] = selectedZipCodes;
        newSet['tenant_zipcode'] = tenant_zipcode;
        newSet['comp_zipcode'] = comp_zipcode;
        newSet['zone'] = zones;
        
        newSideMenuCommonFilterContext['zipcodes'] = selectedZipCodes;
        newSideMenuCommonFilterContext['tenant_zipcode'] = tenant_zipcode;
        newSideMenuCommonFilterContext['comp_zipcode'] = comp_zipcode;
        newSideMenuCommonFilterContext['zone'] = zones;
        
        setFilterBtnsSelected(newSet);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
        handleClose();
        let redirectLocation = window.location.pathname;
        navigate(pageRoutes.tempPage);
        setTimeout(function(){
            navigate(redirectLocation);
        }, 0);
    };

    return (
        <Modal
            size="lg"
            className="modal-custom"
            onHide={handleClose}
            show={showForm} centered
        >
            <Modal.Header closeButton>
                <div className="modal-title">
                    <div className="title">
                        <h4>Choose The Location</h4>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Container className="ps-0">
                    <h6 className="mb-4">Specify the location to which you need the analytics.</h6>
                    <Row className="mb-4" style={{zIndex: '2', position: 'relative'}}>
                        <Col className="col-3">Zone</Col>
                        <Col className="col-9">
                            <Select
                                isMulti={true}
                                menuIsOpen={isZoneMenuOpen}
                                onFocus={e => setIsZoneMenuOpen(true)}
                                onBlur={e => setIsZoneMenuOpen(false)}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, MultiValue }}
                                hideSelectedOptions={false}
                                styles={styles}
                                value={selectedZonValues()}
                                onChange={items => {
                                    let zipCodes = [];
                                    items.map(item => {
                                        zipCodes = zipCodes.concat(zipcodeObjs[item.value]);
                                    });
                                    setSelectedZipCodes(zipCodes);
                                }}
                                options={Object.keys(zipcodeObjs).sort().map(zipcodeObj => {
                                    return {
                                        label: zipcodeObj,
                                        value: zipcodeObj,
                                    }
                                })}
                                placeholder="Select A Zone"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-3">Zipcode</Col>
                        <Col className="col-9">
                            <Select
                                isMulti={true}
                                menuIsOpen={isZipCodeMenuOpen}
                                onFocus={e => setIsZipCodeMenuOpen(true)}
                                onBlur={e => setIsZipCodeMenuOpen(false)}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, MultiValue }}
                                hideSelectedOptions={false}
                                styles={styles}
                                value={selectedZipCodes.map(selectedZipCode => {
                                    return {
                                        label: selectedZipCode,
                                        value: selectedZipCode
                                    }
                                })}
                                onChange={items => setSelectedZipCodes(items.map(item=>item.value))}
                                options={allZipcodes.map(allZipcode => {
                                    return {
                                        label: allZipcode,
                                        value: allZipcode,
                                    }
                                })}
                                placeholder="Select Zipcodes"
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            {loading ? <Skeleton width="100" /> : <>
                                <button 
                                    className="btn btn-primary float-end" 
                                    onClick={e => setFilters()}
                                >Apply</button>
                                <button className="btn btn-outline-secondary float-end me-2" onClick={e => handleClose()} >Cancel</button>
                            </>}
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    )
};

export default ZipcodeConfig;

const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        borderRadius: "4px",
        padding: "3px 6px 3px 5px",
        border: "1px solid #c0c0c0",
        borderRadius: "12px",
        order: 99,
        color: '#316CF4',
        fontWeight: 'bold',
        fontSize: '85%'
    };
  
    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length}`;
  
    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
  
const MultiValue = ({ index, getValue, ...props }) => {
    let stringLimit = 0;
    let maxToShow = 0;
    getValue().map(singleValue => {
        if (stringLimit <= 30) {
            stringLimit += singleValue.value.length;
            maxToShow += 1;
        }
    });
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);
  
    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};