import React from "react";
import { copyrightYear, versionName } from "../common";
import { Container } from "react-bootstrap";

const Footer = (props) => {
    return (<>
        <Container fluid className="p-2 mb-2 mt-2" >
            <div className='custom-container'>
                <small className="ms-2">Copyright @Bungee {copyrightYear()} | Version {versionName()}</small>
            </div>
        </Container>
    </>);
};

export default Footer;