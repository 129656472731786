import { matchPath } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { toolTipMessage } from "../components/common/common";
import moment from "moment";
import SideMenuCommonFilterLib from "../contextApi/SideMenuCommonFilterLib";
import { useContext } from "react";
import { getLocalStorageValue } from "./localStorageOperations";

export const matchActivePath = (currentPath) => {
  return matchPath(window.location.pathname, currentPath)?.isExact;
};

export const getQueryParams = (param) => {
  const params = new URLSearchParams(window?.location?.search);
  let paramObj = {};
  if (param) {
    try {
      paramObj = params.get(param);
    } catch (err) {
      console.log(err);
    }
  } else {
    for (var value of params.keys()) {
      if (value !== undefined) {
        paramObj[value] = params.get(value);
      }
    }
  }
  return paramObj;
};

// newParam => {key: value}
export const addParamstoPage = (newParam) => {
  let paramDivider = "?";
  let params = "";
  const oldParams = getQueryParams() ? getQueryParams() : {};
  const queryParams = { ...oldParams, ...newParam };
  let paramObj = {};
  if (queryParams) {
    for (let id of Object.keys(queryParams)) {
      paramObj[id] = queryParams[id];

      params += paramObj[id] && paramDivider + id + "=" + paramObj[id];
      paramDivider = "&";
    }
  }

  window.history.pushState(null, null, params);
};

export const removeParamstoPage = (param) => {
  let paramDivider = "?";
  let params = "";
  const oldParams = getQueryParams() ? getQueryParams() : {};
  const queryParams = { ...oldParams };
  let paramObj = {};
  if (queryParams) {
    for (let id of Object.keys(queryParams)) {
      if (!param.includes(id)) {
        paramObj[id] = queryParams[id];
        params += paramObj[id] && paramDivider + id + "=" + paramObj[id];
        paramDivider = "&";
      }
    }
  }
  if (params.length == 0) {
    params += paramDivider
  }
  window.history.pushState(null, null, params);
};

// history - props.history can be fetched using withRouter as HOC
// path - string
// state - Object (key value pair)
// newParams - Object (key value pair)
export const redirectTo = (history, path, state = {}, newParams = {}) => {
  let params = "";
  const allParams = { ...getQueryParams(), ...newParams };
  let paramDivider = "?";
  for (let ele in allParams) {
    params += `${paramDivider}${ele}=${allParams[ele]}`;
    paramDivider = "&";
  }

  history.push({
    pathname: path,
    search: params,
    state,
  });
};

export const getStateFromHistory = (history, keyName) => {
  const state = history?.location?.state;
  return state && state[keyName];
};

export const getIntervalDateRange = (interval) => {
  let dates = {
    'from_date': moment(moment().subtract(1, 'days').utc()).format("YYYY-MM-DD"),
    'to_date': moment(moment(new Date()).utc()).format("YYYY-MM-DD")
  };
  if (interval == 'weekly') {
    dates['from_date'] = moment(moment().subtract(7, 'days').utc()).format("YYYY-MM-DD")
  }
  if (interval == 'monthly') {
    dates['from_date'] = moment(moment().subtract(1, 'months').utc()).format("YYYY-MM-DD")
  }
  if (interval == 'yearly') {
    dates['from_date'] = moment(moment().subtract(1, 'years').utc()).format("YYYY-MM-DD")
  }
  return dates;
};

export const getIntervalDateRangeByRefreshDate = (interval, lastRefreshDate) => {
  let fromDate;
  let toDate = moment(moment(lastRefreshDate)).format("YYYY-MM-DD");

  if (interval === 'weekly') {
    fromDate = moment(moment(toDate).subtract(7, 'days')).format("YYYY-MM-DD");
  } else if (interval === 'monthly') {
    fromDate = moment(moment(toDate).subtract(1, 'months')).format("YYYY-MM-DD");
  } else if (interval === 'yearly') {
    fromDate = moment(moment(toDate).subtract(1, 'years')).format("YYYY-MM-DD");
  } else {
    // Default to daily interval
    fromDate = moment(moment(toDate).subtract(1, 'days')).format("YYYY-MM-DD");
  }
  return {
    'from_date': fromDate,
    'to_date': toDate
  };
};

export const generatePricingRuleFilters = (filterBtnsSelected) => {
  let postBody = {};
  let retailers = Array.from(filterBtnsSelected.retailer);
  let categories = Array.from(filterBtnsSelected.category);
  let subCategories = Array.from(filterBtnsSelected.subcategory);
  let subSubCategories = Array.from(filterBtnsSelected.subsubcategory);
  let subSubSubCategories = Array.from(filterBtnsSelected.subsubsubcategory);
  let brands = Array.from(filterBtnsSelected.brand);
  let priorities = Array.from(filterBtnsSelected.tenant_priority);
  let image_competitor = Array.from(filterBtnsSelected.image_competitor);
  let price_relativity = Array.from(filterBtnsSelected.price_relativity);
  let comp_stock_status_flag = Array.from(filterBtnsSelected.comp_stock_status_flag);
  let tenant_stock_status_flag = Array.from(filterBtnsSelected.tenant_stock_status_flag);
  let is_cpi_outlier = Array.from(filterBtnsSelected.is_cpi_outlier);
  let map_violation = Array.from(filterBtnsSelected.map_violation);
  let price = Array.from(filterBtnsSelected.price);
  let tenant_zipcode = Array.from(filterBtnsSelected.tenant_zipcode);
  let comp_zipcode = Array.from(filterBtnsSelected.comp_zipcode);
  let zone = Array.from(filterBtnsSelected.zone);
  let tenant_segment = Array.from(filterBtnsSelected.tenant_segment);

  let filters = {};
  let advfFilters = {};
  let subFilters = {};
  if (categories.length) {
    filters['tenant_category'] = categories;
  }
  if (subCategories.length) {
    filters['tenant_subcategory'] = subCategories;
  }
  if (subSubCategories.length) {
    filters['tenant_sub_subcategory'] = subSubCategories;
  }
  if (subSubSubCategories.length) {
    filters['tenant_sub_sub_subcategory'] = subSubSubCategories;
  }
  if (retailers.length) {
      filters['comp_source'] = retailers;
  }
  if (brands.length) {
    filters['tenant_brand'] = brands;
  }
  if (priorities[0] && priorities[0] != 'ALL') {
    filters['tenant_priority'] = priorities;
  }
  if (image_competitor[0]) {
    if (image_competitor[0].toLowerCase() === 'no') {
      filters['image_competitors'] = false;
    } else if (image_competitor[0].toLowerCase() === 'yes'){
      filters['image_competitors'] = true;
    }
  }
  if(comp_stock_status_flag[0]){
    if(comp_stock_status_flag[0]=='Yes'){
      filters['comp_stock_status_flag'] = true
    }
  }
  if(tenant_stock_status_flag[0]){
    if(tenant_stock_status_flag[0]=='Yes'){
      filters['tenant_stock_status_flag'] = true
    }
  }
  if (is_cpi_outlier.length) {
    if(is_cpi_outlier[0]=='N'){
      filters['is_cpi_outlier'] = false;
    }
    else if(is_cpi_outlier[0]=='Y'){
      filters['is_cpi_outlier'] = true;
    }
  }
  if (map_violation.length) {
    if(map_violation[0]=='N'){
      filters['map_violation'] = false;
    }
    else if(map_violation[0]=='Y'){
      filters['map_violation'] = true;
    }
  }
  if (tenant_zipcode.length) {
    filters['tenant_zipcode'] = tenant_zipcode;
  }
  if (comp_zipcode.length) {
    filters['comp_zipcode'] = comp_zipcode;
    filters['zone'] = zone;
  }
  if (price_relativity.length) {
    filters['price_relativity'] = price_relativity;
  }
  if (price.length) {
    /*if(price[0]=='Effective TPR Price'){
      filters['price'] = 'effective_price';
    }
    else if(price[0]=='Regular Price'){
      filters['price'] = 'regular_price';
    }
    else if(price[0]=='TPR Price'){
      filters['price'] = 'promo_price';
    }*/
    filters['price'] = price[0];
  }

  if (tenant_segment.length) {
    filters['segment'] = tenant_segment[0];
  }

  let advancedFilter = getLocalStorageValue('tenantFeatureConfig');
  if (advancedFilter) {
    advancedFilter = JSON.parse(advancedFilter);
    advancedFilter = advancedFilter.advanced_filter_config;
    if (advancedFilter) {
      Object.keys(advancedFilter).map(advancedFilterIndex => {
        if (filterBtnsSelected[advancedFilterIndex]) {
          let advanceFilterValue = Array.from(filterBtnsSelected[advancedFilterIndex]);
          if (advanceFilterValue.length) {
            advfFilters[advancedFilterIndex] = advanceFilterValue;
          }
        }
      });
    }
  }

  if (Object.keys(filters).length) {
    postBody['filters'] = filters;
  }
  if (Object.keys(advfFilters).length) {
    postBody['advancedFilters'] = advfFilters;
  }
  //postBody['subFilters'] = subFilters;
  return postBody;
}

export const generatePricingFilters = (filterBtnsSelected) => {
  let postBody = {};
  let filterConfigValues = JSON.parse(localStorage.getItem("tenantFeatureConfig"))?.["filter_config_values"];
  let retailers = Array.from(filterBtnsSelected.retailer);
  let categories = Array.from(filterBtnsSelected.category);
  let subCategories = Array.from(filterBtnsSelected.subcategory);
  let subSubCategories = Array.from(filterBtnsSelected.subsubcategory);
  let subSubSubCategories = Array.from(filterBtnsSelected.subsubsubcategory);
  let brands = Array.from(filterBtnsSelected.brand);
  let priorities = Array.from(filterBtnsSelected.tenant_priority);
  let image_competitor = Array.from(filterBtnsSelected.image_competitor);
  let postal_code = Array.from(filterBtnsSelected.postalCode);
  let comp_stock_status_flag = Array.from(filterBtnsSelected.comp_stock_status_flag);
  let tenant_stock_status_flag = Array.from(filterBtnsSelected.tenant_stock_status_flag);
  let price_relativity = Array.from(filterBtnsSelected.price_relativity);
  let is_cpi_outlier = Array.from(filterBtnsSelected.is_cpi_outlier);
  let map_violation = Array.from(filterBtnsSelected.map_violation);
  let price = Array.from(filterBtnsSelected.price);
  let tenant_segment = Array.from(filterBtnsSelected.tenant_segment);
  let tenant_zipcode = Array.from(filterBtnsSelected.tenant_zipcode);
  let comp_zipcode = Array.from(filterBtnsSelected.comp_zipcode);
  let zone = Array.from(filterBtnsSelected.zone);
  let filters = {};
  let advfFilters = {};
  let subFilters = {};
  if (categories.length) {
    filters['tenant_category'] = categories;
  }
  if (subCategories.length) {
    filters['tenant_subcategory'] = subCategories;
  }
  if (subSubCategories.length) {
    filters['tenant_sub_subcategory'] = subSubCategories;
  }
  if (subSubSubCategories.length) {
    filters['tenant_sub_sub_subcategory'] = subSubSubCategories;
  }
  if (retailers.length) {
      filters['comp_source'] = retailers;
  }
  if (brands.length) {
    filters['tenant_brand'] = brands;
  }
  if (postal_code.length) {
    filters['postal_code'] = postal_code;
  }
  if (priorities[0] && priorities[0] != 'ALL') {
    if(filterConfigValues != null && 'priority_filter' in filterConfigValues)
    {
      if(priorities[0] == 'Y')
        priorities[0] = 'High'
      else priorities[0] = 'Low'
    }
    filters['tenant_priority'] = priorities;
  }
  if (image_competitor[0]) {
    if (image_competitor[0].toLowerCase() === 'no') {
      filters['image_competitors'] = false;
    } else if (image_competitor[0].toLowerCase() === 'yes'){
      filters['image_competitors'] = true;
    }
  }
  if(comp_stock_status_flag[0]){
    if(comp_stock_status_flag[0]=='Yes'){
      filters['comp_stock_status_flag'] = true
    }
  }
  if(tenant_stock_status_flag[0]){
    if(tenant_stock_status_flag[0]=='Yes'){
      filters['tenant_stock_status_flag'] = true
    }
  }
  if (price_relativity.length) {
    filters['price_relativity'] = price_relativity;
  }
  if (is_cpi_outlier.length) {
    if(is_cpi_outlier[0]=='N'){
      filters['is_cpi_outlier'] = false;
    }
    else if(is_cpi_outlier[0]=='Y'){
      filters['is_cpi_outlier'] = true;
    }
  }
  if (map_violation.length) {
    if(map_violation[0]=='N'){
      filters['map_violation'] = false;
    }
    else if(map_violation[0]=='Y'){
      filters['map_violation'] = true;
    }
  }
  if (tenant_zipcode.length) {
    filters['tenant_zipcode'] = tenant_zipcode;
  }
  if (comp_zipcode.length) {
    filters['comp_zipcode'] = comp_zipcode;
    filters['zone'] = zone;
  }
  if (price.length) {
    /*if(price[0]=='Effective TPR Price'){
      filters['price'] = 'effective_price';
    }
    else if(price[0]=='Regular Price'){
      filters['price'] = 'regular_price';
    }
    else if(price[0]=='TPR Price'){
      filters['price'] = 'promo_price';
    }*/
    filters['price'] = price[0];
  }
  if (tenant_segment.length) {
    filters['segment'] = tenant_segment[0];
  }

  let advancedFilter = getLocalStorageValue('tenantFeatureConfig');
  if (advancedFilter) {
    advancedFilter = JSON.parse(advancedFilter);
    advancedFilter = advancedFilter.advanced_filter_config;
    if (advancedFilter) {
      Object.keys(advancedFilter).map(advancedFilterIndex => {
        if (filterBtnsSelected[advancedFilterIndex]) {
          let advanceFilterValue = Array.from(filterBtnsSelected[advancedFilterIndex]);
          if (advanceFilterValue.length) {
            advfFilters[advancedFilterIndex] = advanceFilterValue;
          }
        }
      });
    }
  }

  if (Object.keys(filters).length) {
    postBody['filters'] = filters;
  }
  if (Object.keys(advfFilters).length) {
    postBody['advancedFilters'] = advfFilters;
  }
  //postBody['subFilters'] = subFilters;

  return postBody;
};

export const generateFilters = (filterBtnsSelected) => {
  let postBody = {};
  let retailers = Array.from(filterBtnsSelected.retailer);
  let categories = Array.from(filterBtnsSelected.category);
  let subcategories = Array.from(filterBtnsSelected.subcategory);
  let subsubcategories = Array.from(filterBtnsSelected.subsubcategory);
  let subsubsubcategories = Array.from(filterBtnsSelected.subsubsubcategory);
  let brands = Array.from(filterBtnsSelected.brand);
  let best_seller = Array.from(filterBtnsSelected.best_seller);
  let brand_gap = Array.from(filterBtnsSelected.brand_gap);
  let private_label = Array.from(filterBtnsSelected.private_label);
  let image_competitor = Array.from(filterBtnsSelected.image_competitor);
  // let assortment_score = Array.from(filterBtnsSelected.assortment_score);
  // let economic_performance_score = Array.from(filterBtnsSelected.economic_performance_score);
  // let customer_value_score = Array.from(filterBtnsSelected.customer_value_score);
  // let catalog_strategy_score = Array.from(filterBtnsSelected.catalog_strategy_score);
  let rating = Array.from(filterBtnsSelected.rating);
  let third_party = Array.from(filterBtnsSelected.third_party);
  let review_count = Array.from(filterBtnsSelected.review_count);
  /*let yearly_revenue = Array.from(filterBtnsSelected.yearly_revenue);
  let non_amazon_review_count = Array.from(filterBtnsSelected.non_amazon_review_count);
  let category_rank = Array.from(filterBtnsSelected.category_rank);*/
  let filters = {};
  let subFilters = {};
  if (categories.length) {
    filters['tenant_category'] = categories;
  }
  if (subcategories.length) {
    filters['tenant_subcategory'] = subcategories;
  }
  if (subsubcategories.length) {
    filters['tenant_sub_subcategory'] = subsubcategories;
  }
  if (subsubsubcategories.length) {
    filters['tenant_sub_sub_subcategory'] = subsubsubcategories;
  }
  if (retailers.length) {
      filters['source'] = retailers;
  }
  if (brands.length) {
    filters['normalised_brand'] = brands;
  }
  if (best_seller[0]) {
    if (best_seller[0].toLowerCase() === 'yes') {
      filters['best_seller_flag'] = true;
    } else if (best_seller[0].toLowerCase() === 'no') {
      filters['best_seller_flag'] = false;
    }
  }
  if (brand_gap[0]) {
    if (brand_gap[0] === 'Gap Brands') {
      filters['brand_gap'] = true;
    } else if (brand_gap[0] === 'Existing Brands') {
      filters['brand_gap'] = false;
    }
  }
  if (private_label[0]) {
    if (private_label[0] === 'Only Private Labels') {
      filters['private_label'] = true;
    } else if (private_label[0] === 'Exclude Private Labels') {
      filters['private_label'] = false;
    }
  }
  if (image_competitor[0]) {
    if (image_competitor[0].toLowerCase() === 'no') {
      filters['image_competitors'] = false;
    } else if (image_competitor[0].toLowerCase() === 'yes'){
      filters['image_competitors'] = true;
    }
  }
  // if (assortment_score[0] != 0 || assortment_score[1] != 100) {
  //   subFilters['assortment_score'] = {
  //     greaterThan: assortment_score[0],
  //     lesserThan: assortment_score[1]
  //   };
  // }
  // if (economic_performance_score[0] != 0 || economic_performance_score[1] != 100) {
  //   subFilters['economic_performance_score'] = {
  //     greaterThan: economic_performance_score[0],
  //     lesserThan: economic_performance_score[1]
  //   };
  // }
  // if (customer_value_score[0] != 0 || customer_value_score[1] != 100) {
  //   subFilters['customer_value_score'] = {
  //     greaterThan: customer_value_score[0],
  //     lesserThan: customer_value_score[1]
  //   };
  // }
  // if (catalog_strategy_score[0] != 0 || catalog_strategy_score[1] != 100) {
  //   subFilters['catalog_strategy_score'] = {
  //     greaterThan: catalog_strategy_score[0],
  //     lesserThan: catalog_strategy_score[1]
  //   };
  // }
  if (rating[0] != 0 || rating[1] != 5) {
    if (rating.length == 2) {
      subFilters['rating'] = {
        greaterThan: rating[0],
        lesserThan: rating[1]
      };
    }
  }
  if (third_party[0]) {
    if (third_party[0].toLowerCase() == '3p') {
      filters['third_party'] = true;
    } else if (third_party[0].toLowerCase() == '1p') {
      filters['third_party'] = false;
    }
  }
  if (review_count) {
    const [minValue, maxValue] = review_count;
    subFilters['review_count'] = {
        greaterThan: +minValue,
        lesserThan: +maxValue
      };
  }
  /*if (yearly_revenue[0] != 0 || yearly_revenue[1] != 35) {
      subFilters['yearly_revenue'] = {
          greaterThan: yearly_revenue[0],
          lesserThan: yearly_revenue[1]
      };
  }
  }
  if (non_amazon_review_count[0]) {
      subFilters['non_amazon_review_count'] = {
          greaterThan: 0,
          lesserThan: parseInt(non_amazon_review_count[0])
      };
  }
  if (category_rank[0]) {
      subFilters['category_rank'] = {
          greaterThan: 0,
          lesserThan: parseInt(category_rank[0])
      };
  }*/
  postBody['filters'] = filters;
  postBody['subFilters'] = subFilters;
  return postBody;
};

export const generatePagination = (totalCount, limit, page) => {
  let totalPages = Math.ceil(totalCount/limit);
  let currentPage = page;
  let pageArr = [];
  if (totalPages > 1) {
      if (totalPages <= 3) {
          let i = 1;
          while (i <= totalPages) {
              pageArr.push(i);
              i++;
          }
      } else {
          if (currentPage <= 2) {
              pageArr = [1, 2, "", totalPages];
          } else if (totalPages - currentPage <= 1) {
              pageArr = [
                  1,
                  "",
                  totalPages - 1,
                  totalPages
                ];
          } else {
              pageArr = [
                  1,
                  "",
                  currentPage,
                  "",
                  totalPages
                ];
          }
      }
    }
    return (pageArr);
};

export const triggerFilterEvent = (current, updated) => {
  /*Object.keys(updated).map(updatedSingle => {
    let currentValue = Array.from(current[updatedSingle]);
    let updatedValue = Array.from(updated[updatedSingle]);
    if (currentValue.join(',') != updatedValue.join(',')) {
      if (updatedValue.length) {
        if (['best_seller', 'third_party', 'tenant_priority'].includes(updatedSingle) && updatedValue[0] == 'ALL') {
          return false;
        }
        if (['brand_gap', 'private_label', 'image_competitor'].includes(updatedSingle) && updatedValue[0] == false) {
          return false;
        }
        if (['assortment_score', 'economic_performance_score', 'customer_value_score', 'catalog_strategy_score'].includes(updatedSingle) && updatedValue[0] == 0 && updatedValue[1] == 100) {
          return false;
        }
        if (['rating'].includes(updatedSingle) && updatedValue[0] == 0 && updatedValue[1] == 5) {
          return false;
        }
        let newValue = updatedValue.filter(singleUpdatedValue => !currentValue.includes(singleUpdatedValue));
        if (newValue.length) {
          //console.log('filter', updatedSingle, currentValue, updatedValue, newValue);
          newValue.map(newSingleValue => {
            let newfiterEvent = {};
            newfiterEvent['filter_' + updatedSingle] = newSingleValue;
            console.log('filter', newfiterEvent);
            window.gtag("event", "filter", newfiterEvent);
          });
        }
      }
    }
  });*/
};

export const formateDate = (dateString) => {
  if (dateString) {
    let dateStringDate = dateString.substring(0, 4) + "-" + dateString.substring(4, 6) + "-" + dateString.substring(6, 8);
    return dateStringDate;
  } else {
    return dateString;
  }
};

export const generateRefreshDate = (whatToShow) => {
  const {
    showNextRefresh = false,
    showLastRefresh = true,
    module = 'assortment',
  } = whatToShow;
  let staticLastRefreshDate = "2022-10-04";
  let staticNextRefreshDate;
  if (localStorage.getItem("lastRefreshDate")) {
    let lastRefreshedDateObj = JSON.parse(localStorage.getItem("lastRefreshDate"));
    Object.keys(lastRefreshedDateObj).map(lastRefreshedDateKey => {
      if (lastRefreshedDateKey.toLowerCase() == module) {
        staticLastRefreshDate = lastRefreshedDateObj[lastRefreshedDateKey];
      }
    });
  }

  if (localStorage.getItem("nextRefreshDate")) {
    let nextRefreshDateObj = JSON.parse(localStorage.getItem("nextRefreshDate"));
    Object.keys(nextRefreshDateObj).map(nextRefreshDateKey => {
      if (nextRefreshDateKey.toLowerCase() == module) {
        staticNextRefreshDate = nextRefreshDateObj?.[nextRefreshDateKey];
      }
    });
  }

  const refreshInfo = () => {
    return (<p className="float-end me-3 pe-3 mb-0">Refreshed Date{toolTipMessage('This indicates the time at which data was refreshed in the application and the next time slot for data refresh')}</p>);
  };

  return (<Row>
    { staticNextRefreshDate ?
      (<>
      <div style={{width: 'auto'}}>
          {showNextRefresh && <p className="float-end mb-0 me-2 pe-4 right-border">Next: <strong>{formateDate(staticNextRefreshDate)}</strong></p>}
          {showLastRefresh && <p className="float-end me-4 pe-4 right-border mb-0">Last: <strong>{formateDate(staticLastRefreshDate)}</strong></p>}
          {(showNextRefresh || showLastRefresh) ? refreshInfo() : <p className="float-end me-3 pe-3 mb-0">&nbsp;</p>}
        </div></>) :
      (<><div style={{width: 'auto'}}>
            {showLastRefresh && <p className="float-end mb-0 me-2 pe-4 right-border">Last: <strong>{formateDate(staticLastRefreshDate)}</strong></p>}
            {showLastRefresh ? refreshInfo() : <p className="float-end me-3 pe-3 mb-0">&nbsp;</p>}
        </div></>)
    }
  </Row>);
}