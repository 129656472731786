import React, { useState, useEffect, useContext } from "react";
import Select, { StylesConfig, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter";
import { apiCall } from "../common.js";
import {
    filterOptionApi,
    brandCount,
    brandAutoCompleteList
} from "../constants.js";
import { getLocalStorageValue } from "../../../utils/localStorageOperations";

const FilterSelect = (props) => {
    const {
        ele,
        filterBtnsSelected,
        openFilterLayout,
        filterBySelectedValues,
    } = props;
    let timer;
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const accessor = ele.accessor;
    const selected = openFilterLayout === ele.name;
    const list = ele?.list;
    const customApiListVals = ('customApiList' in ele && ele.customApiList.length) ? ele.customApiList.map(option => {
        return option.value;
    }) : [];
    const [loading, setLoading] = useState(false);
    const [dynamicOptions, setDynamicOptions] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dynamicText, setDynamicText] = useState("");
    const [noOptionsMessage, setNoOptionsMessage] = useState(ele.label);
    const tenant = localStorage.getItem("tenant");

    const [defaultValue, setDefaultValue] = useState(filterBtnsSelected[accessor] ? Array.from(filterBtnsSelected[accessor]).map(filterBtnsSelectedSingle => {
        let filterBtnsSelectedSingleLebel;
        list.map(listSingle => {
            if (typeof listSingle[accessor] == 'string' && typeof filterBtnsSelectedSingle == 'string') {
                if (listSingle[accessor].toLowerCase() == filterBtnsSelectedSingle.toLowerCase()) {
                    filterBtnsSelectedSingleLebel = listSingle['label'] ? listSingle['label'] : listSingle[accessor];
                }
            } else {
                if (listSingle[accessor] == filterBtnsSelectedSingle) {
                    filterBtnsSelectedSingleLebel = listSingle['label'] ? listSingle['label'] : listSingle[accessor];
                }
            }
        });
        if (ele.autoSuggest) {
            filterBtnsSelectedSingleLebel = filterBtnsSelectedSingle;
        }
        if (ele?.customApiList) {
            ele.customApiList.map(listSingle => {
                if (listSingle['value'].toLowerCase() == filterBtnsSelectedSingle.toLowerCase()) {
                    filterBtnsSelectedSingleLebel = listSingle['label'] ? listSingle['label'] : listSingle['value'];
                }
            });
        }
        return {
            label: filterBtnsSelectedSingleLebel,
            value: filterBtnsSelectedSingle,
            //isFixed: (Array.from(filterBtnsSelected[accessor]).length == 1) ? true : false
        };
    }) : []);

    const options = list.map(listSingle => {
        return {
            label: listSingle['label'] ? listSingle['label'] : listSingle[accessor],
            value: listSingle[accessor]
        };
    });

    const checkValidList = (value) => {
        let valid = true;
        if (customApiListVals.includes(value)) {
            valid = false
        }
        return valid;
    };
    
    const styles: StylesConfig<ColourOption, true> = {
        control: (base, state) => ({
            ...base,
            "border-top": "0",
            "border-left": "0",
            "border-right": "0",
            "border-radius": "0",
            boxShadow: state.isFocused ? 0 : 0,
        }),
        singleValue: (base, state) => ({
            ...base,
            "color": "#316CF4"
        }),
        multiValue: (base, state) => ({
            ...base,
            "color": "#316CF4",
            "background-color": "#fff",
            "border": "1px solid #c0c0c0",
            "border-radius": "12px",
            "max-width": "75%",
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        //menu: provided => ({ ...provided, zIndex: 9999 }),
        multiValueRemove: (base, state) => ({
            ...base,
            "opacity": (state.data.value == "ALL") ? "0" : "1",
            "width": (state.data.value == "ALL") ? "0" : "auto",
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                "border-top-right-radius": "12px",
                "border-bottom-right-radius": "12px",
            },
        }),
        multiValueLabel: (styles: any) => ({
            ...styles,
            whiteSpace: "normal",
            "color": "#316CF4",
            "white-space": "nowrap",
            "overflow": "hidden",
            "text-overflow": "ellipsis",
            "max-width": "85%",
        }),
        option: (base, {isFocused, isSelected, isMulti, value}) => ({
            ...base,
            color: '#000',
            fontWeight: (!checkValidList(value)) ? 'bold' : undefined,
            background: isFocused
                ? 'rgba(0, 0, 0, 0.2)'
                : isSelected
                    ? 'rgba(0, 0, 0, 0.07)'
                    : undefined,
            ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
            ":before": {
                content: (isMulti && checkValidList(value)) 
                    ?  isSelected
                        ? '"☑ "' 
                        : '"☐ "' 
                    : '""'
            }
        }),
    };

    const onChange = (item) => {
        let copySet = new Set();
        if (ele.singleSelection) {
            setDefaultValue(item);
            copySet.add(item.value);
            filterBySelectedValues({ [ele.name]: copySet });
        } else {
            let itemNew = item.filter(filterBtnsSelectedSingle => {
                return filterBtnsSelectedSingle.value != "ALL";
            }).map(filterBtnsSelectedSingle => {
                copySet.add(filterBtnsSelectedSingle.value);

                if (!checkValidList(filterBtnsSelectedSingle.value)) {
                    filterBySelectedValues({[ele.name]: copySet});
                }
                
                return {
                    label: filterBtnsSelectedSingle.label,
                    value: filterBtnsSelectedSingle.value,
                    //isFixed: (item.length == 1) ? true : false
                };
            });
            setDefaultValue(itemNew);
        }
    };

    const loadOptions = (inputValue, callbackLoadOptions) => {
        if (ele.apiOptions && inputValue.length) {
            return loadApiOptions(inputValue, callbackLoadOptions)
        } else {
            let filterList = [];
            list.map(listSingle => {
                if (listSingle[accessor].toLowerCase().includes(inputValue) && filterList.length <= 100) {
                    filterList.push(listSingle[accessor]);
                }
            });
            callbackLoadOptions(filterList.map(filterBrand => {
                return {
                    label: filterBrand,
                    value: filterBrand
                }
            }));
        }
    };

    const loadApiOptions = (inputValue, callbackLoadOptions) => {
        let filterAdvanced = {};
        let customApiValuesLocal = {};
        if (localStorage.getItem('customApiValuesLocal')) {
            try {
                customApiValuesLocal = JSON.parse(localStorage.getItem('customApiValuesLocal'));
            } catch(err) {}
        }

        if (Array.from(sideMenuCommonFilterContext.category).length && ele.autoSuggest != 'Category' && !ele.apiUrl) {
            filterAdvanced['tenant_category'] = Array.from(sideMenuCommonFilterContext.category);
        }
        if (Array.from(sideMenuCommonFilterContext.report_category).length && ele.autoSuggest != 'ReportCategory' && ele.apiUrl) {
            if ('report_category' in customApiValuesLocal) {
                filterAdvanced['category'] = customApiValuesLocal.report_category;
            } else {
                filterAdvanced['category'] = Array.from(sideMenuCommonFilterContext.report_category);
            }
        }

        if (Array.from(sideMenuCommonFilterContext.subcategory).length && ele.autoSuggest != 'SubCategory' && !ele.apiUrl) {
            filterAdvanced['tenant_subcategory'] = Array.from(sideMenuCommonFilterContext.subcategory);
        }
        if (Array.from(sideMenuCommonFilterContext.report_subcategory).length && ele.autoSuggest != 'ReportSubCategory' && ele.apiUrl) {
            if ('report_subcategory' in customApiValuesLocal) {
                filterAdvanced['subcategory'] = customApiValuesLocal.report_subcategory;
            } else {
                filterAdvanced['subcategory'] = Array.from(sideMenuCommonFilterContext.report_subcategory);
            }
        }

        if (Array.from(sideMenuCommonFilterContext.subsubcategory).length && ele.autoSuggest != 'SubSubCategory' && !ele.apiUrl) {
            filterAdvanced['tenant_sub_subcategory'] = Array.from(sideMenuCommonFilterContext.subsubcategory);
        }

        if (Array.from(sideMenuCommonFilterContext.report_priority).length && ele.autoSuggest != 'ReportPriority' && ele.apiUrl) {
            filterAdvanced['priority'] = Array.from(sideMenuCommonFilterContext.report_priority);
        }

        if (Array.from(sideMenuCommonFilterContext.brand).length && ele.autoSuggest != 'PricingBrand' && ele.autoSuggest != 'AssortmentBrand' && !ele.apiUrl) {
            if (window.location.pathname != '/priceLeaderAssortment' && window.location.pathname != '/priceLeaderPricing') {
                filterAdvanced['normalised_brand'] = Array.from(sideMenuCommonFilterContext.brand);
            }
        }
        if (Array.from(sideMenuCommonFilterContext.report_brand).length && ele.autoSuggest != 'ReportBrand' && ele.apiUrl) {
            filterAdvanced['brand'] = Array.from(sideMenuCommonFilterContext.report_brand);
        }
        if (Array.from(sideMenuCommonFilterContext.tenant_segment).length && ele.autoSuggest != 'TenantSegment') {
            filterAdvanced['segment'] = Array.from(sideMenuCommonFilterContext.tenant_segment);
        }

        let postBody = {
            "searchKey": ele.autoSuggest,
            "text": inputValue,
            "offset": 0,
            "modules": localStorage.currentModule,
            "filters": filterAdvanced
        };
        if(brandAutoCompleteList.includes(postBody["searchKey"])){
            postBody["size"] = brandCount;
        }
        let apiUrl = filterOptionApi;
        if (ele.apiUrl) {
            apiUrl = ele.apiUrl;
        }
        apiCall("POST", apiUrl, {}, postBody)
        .then((response) => {
            if (response.data.data) {
                if(tenant == 'bjs'){
                    if(ele.autoSuggest == 'Category' && response?.data?.data.includes('Food')){
                        let index = response?.data?.data.indexOf('Food');
                        if (index > -1) { 
                            response?.data?.data.splice(index, 1); 
                        }
                    } 
                    if(ele.autoSuggest == 'SubCategory' && response?.data?.data.includes('Breakfast & Cereals')){
                        let index = response?.data?.data.indexOf('Breakfast & Cereals');
                        if (index > -1) { 
                            response?.data?.data.splice(index, 1); 
                        }
                    } 
                }
                let options = response.data.data.map(dataSingle => {
                    return {
                        label: dataSingle,
                        value: dataSingle
                    };
                });
                callbackLoadOptions(options);
            }
        })
        .catch((error) => {
            console.log("ERROR", error);
            callbackLoadOptions([]);
        });
    };

    const setFinalValues = (e) => {
        setIsMenuOpen(false);
        let copySet = new Set();
        defaultValue.map(filterBtnsSelectedSingle => {
            copySet.add(filterBtnsSelectedSingle.value);
        });
        filterBySelectedValues({ [ele.name]: copySet });
    };

    const addCutomApiOptions = (options) => {
        let newOptions = [];
        if (options.length && 'customApiList' in ele && ele.customApiList.length && defaultValue.length == 0) {
            newOptions = newOptions.concat(ele.customApiList, options);
        } else {
            newOptions = options;
        }
        if (loading) {
            return [];
        } else {
            return newOptions;
        }
    };

    const loadFocus = (e) => {
        setIsMenuOpen(true);
        setNoOptionsMessage("Loading");
        setDynamicOptions([]);
        loadApiOptions("", function(resultOptions) {
            console.log(ele, filterBtnsSelected);
            if (JSON.parse(getLocalStorageValue('tenantFeatureConfig'))['allowedFeatures'].includes('user_personalization_filter_restriction') && filterBtnsSelected['savedFilter'] && filterBtnsSelected['savedFilter'].size !==0) {
                let filterResultOptions = [];
                for(let result of resultOptions) {
                    if (!filterBtnsSelected['savedFilter'][0][ele.accessor]) {
                        filterResultOptions.push(result);
                    }
                    else if (filterBtnsSelected['savedFilter'][0][ele.accessor].has(result.value)) {
                        filterResultOptions.push(result);
                    }
                }
                setDynamicOptions(filterResultOptions);
            } else {
                setDynamicOptions(resultOptions);
            }
        })
    };

    const loadInputChange = (text) => {
        if (text.length || dynamicText.length) {
        //if (text.length) {
            if (timer){
                clearTimeout(timer);
            }
            timer = setTimeout(function(){
                setNoOptionsMessage("Loading");
                setDynamicOptions([]);
                setDynamicText(text);
                loadApiOptions(text, function(resultOptions) {
                    console.log(resultOptions);
                    setDynamicOptions(resultOptions);
                    if (resultOptions.length == 0) {
                        setNoOptionsMessage("No Options");
                    }
                    
                });
            }, 400);
        } else {
            //setDynamicOptions([]);
            //setNoOptionsMessage(ele.label);
        }
    };

    const filterOptions = (
        candidate: { label: string; value: string; data: any },
        input: string
    ) => {
        return true;
    };

    const populateValues = () => {
        if (defaultValue.length) {
            return defaultValue;
        } else {
            return [{
                label: "ALL",
                value: "ALL",
                isFixed: true
            }]
        }
    };

    const isConfigValues = () => {
        let values = populateValues();
        if (!checkValidList(values[0].value)) {
            return true;
        }
        return false;
    };

    const renderSelect = () => {
        if (isConfigValues()) {
            let values = populateValues(); 
            return (<div className="border border-2 border-top-0 border-start-0 border-end-0 w-100" style={{padding: '3px'}}>
                <div className="btn-group btn-group-sm border border-2 rounded-pill w-50" style={{marginTop: '2.4px'}}>
                    <button className="btn btn-link text-primary text-decoration-none w-75 text-start pt-1 pb-1">{values[0].label}</button>
                    <button className="btn btn-link text-primary text-decoration-none fw-bold text-end pt-1 pb-1" onClick={e => {
                        onChange([]);
                        filterBySelectedValues({[ele.name]: new Set([])});
                    }}>X</button>
                </div>
            </div>);
        } else if (ele.autoSuggest) {
            return (<Select
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, MultiValueLabel, MultiValue }}
                hideSelectedOptions={false}
                menuIsOpen={isMenuOpen}
                name={accessor}
                isMulti={!ele.singleSelection}
                isClearable={(!ele.singleSelection && populateValues().length >= 2)}
                styles={styles}
                defaultValue={populateValues()}
                value={populateValues()}
                options={addCutomApiOptions(dynamicOptions)}
                onFocus={loadFocus}
                onBlur={setFinalValues}
                onInputChange={loadInputChange}
                placeholder={ele.label}
                onChange={onChange}
                noOptionsMessage={() => noOptionsMessage}
                filterOption={filterOptions}
                menuPortalTarget={document.body}
                isDisabled={ele.isDisabled}
            />);
        } else {
            return (<Select
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, MultiValueLabel, MultiValue }}
                hideSelectedOptions={false}
                menuIsOpen={isMenuOpen}
                name={accessor}
                isMulti={!ele.singleSelection}
                isClearable={(!ele.singleSelection && populateValues().length >= 2)}
                styles={styles}
                defaultValue={populateValues()}
                value={populateValues()}
                options={addCutomApiOptions(options)}
                onFocus={e => setIsMenuOpen(true)}
                onBlur={setFinalValues}
                placeholder={ele.label}
                onChange={onChange}
                noOptionsMessage={() => noOptionsMessage}
                menuPortalTarget={document.body}
                isDisabled={ele.isDisabled}
            />);
        }
    };

    return (<div
        key={ele.name}
        title={ele.label}
        className={
          `filter-name ${ele.advance ? "filter-advanced" : ""} ${ele?.size ? ele?.size : ""} ${ele.name}-label-filter-name` +
          (selected ? "selected" : "") +
          (ele.disabled ? " disabled" : "")
        }
      >
        {populateValues().length ? <div className="name-top">{ele.label}</div> : ""}
        {renderSelect()}
      </div>);
};

export default FilterSelect;

const MultiValueLabel = ({...props}) => {
    if (props?.innerProps) {
        props.innerProps.title = props?.data?.label;
    }
    return (<components.MultiValueLabel {...props} />);
};

const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        borderRadius: "4px",
        padding: "3px 6px 3px 5px",
        border: "1px solid #c0c0c0",
        borderRadius: "12px",
        order: 99,
        color: '#316CF4',
        fontWeight: 'bold',
        fontSize: '85%'
    };
  
    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length}`;
  
    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
  
const MultiValue = ({ index, getValue, ...props }) => {
    let stringLimit = 0;
    let maxToShow = 0;
    getValue().map(singleValue => {
        if (stringLimit <= 7) {
            stringLimit += singleValue.value.length;
            maxToShow += 1;
        }
    });
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);
  
    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};