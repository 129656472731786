import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faL } from "@fortawesome/free-solid-svg-icons";
import { decodeToken } from "react-jwt";
import validator from "validator";

import * as pageRoutes from "../../pageRoutes.js";
import { apiCall } from "../common/common";
import Loader from "../common/Loader";
import { loginApi, signUpApi, verifyEmailApi, resendVerifyEmailApi } from "../common/constants";
import LoginFooter from "../Login/LoginFooter";
import { setLocalStorageValue, getLocalStorageValue } from "../../utils/localStorageOperations";
import { checkSessionStatus, getTenantInfo } from "../../utils/loginSessionOperations";
import { encryptPassword } from "../../utils/passwordEncryption";

import "./signup.scss";

const Signup = (props) => {
    const navigate = useNavigate();
    const { search } = useLocation(); 
    const urlParams = new URLSearchParams(search);
    const proc = urlParams.get('proc');

    const [title, setTitle] = useState('Start Your Journey with us!')
    const [subTitle, setSubTitle] = useState('Discover Bungee Retailscape Analytics Tool');
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorList, setErrorList] = useState([]);
    const [accountVerified, setAccountVerified] = useState(false);
    const [state, setState] = useState({
        name: { isFocused: false, isErrored: false, error: '', value: '' },
        email: { isFocused: false, isErrored: false, error: '', value: '' },
        password: { isFocused: false, isErrored: false, error: '', value: '' },
        confirmpassword: { isFocused: false, isErrored: false, error: '', value: '' },
        code: { isFocused: false, isErrored: false, error: '', value: '' }
    });

    useEffect(() => {
        if (proc == 'verify') {
            setTitle('Please Verify your Account');
            setSubTitle('Check your email for a verification link');
            login(true);
        } else if (proc == 'confirmaccount') {
            setTitle('Verifying Account');
            setSubTitle('');
            verifyAccount();
        }
    }, [proc]);

    useEffect(() => {
        if (Object.values(state).some((field) => field.isErrored === true) || errorList.length) {
            return;
        } else if (formReady) {
            signup();
        }
    }, [formReady]);

    useEffect(() => {
        const idToken = getLocalStorageValue("id_token");
        const decodedObj = decodeToken(idToken);
        const expirationTime = decodedObj?.exp * 1000;
        const currentTime = Date.now();
        if (expirationTime >= currentTime) {
            checkSessionStatus(idToken).then((value) => {
                if (value) {
                    navigate(pageRoutes.dashboardPage);
                }
            });
        } else {
            document.title = "Retailscape - Register";
        }
    }, [navigate]);

    // ------------------------------------------------------------
    // Helper Functions
    // ------------------------------------------------------------
    const login = async (automated) => {
        setErrorList([]) ;
        let email = urlParams.get('e');
        let encryptedPassword = urlParams.get('p') || '';
        let password = (encryptedPassword).replace(/\ /g, '+');
        if (!password || password == '') {
            if (state.password.value == '' || state.password.isErrored) {
                setErrorList(['Password invalid']) ;
                return;
            } else {
                password = await encryptPassword(state.password.value)
            }
        }
        const payload = {
            emailAddress: email,
            password
        };
        setLoading(true);
        apiCall("POST", loginApi, "", payload).then(function (response) {
            console.log(response)
            if (response.status === 200) {
                if (!response.data.data.access_token) {
                    alert(response.data.message);
                    return false;
                }
                setLocalStorageValue("tenant", response.data.data.tenant_code);
                setLocalStorageValue("access_token", response.data.data.access_token);
                setLocalStorageValue("id_token", response.data.data.id_token);
                setLocalStorageValue("passThrough", false);
                setLocalStorageValue("sso_signin", false)
                setLocalStorageValue("refresh_token", response.data.data.refresh_token);
                setLocalStorageValue("email", state.email.value);
                setLocalStorageValue("loginEvent", 1);
                setLocalStorageValue("notificationCount", JSON.stringify({ count: 0, date: (new Date()).toISOString() }));                
                getTenantInfo().then((tenantSet) => {
                    setLoading(false);
                    if (tenantSet) {
                        navigate(pageRoutes.loginPage);
                    } else {
                        localStorage.clear();
                        setErrorList(['Something went wrong. Please contact your Bungee administrator.'])
                    }
                });
            } else {
                setErrorList(['Failed to authenticate.']);
                setLoading(false);
            }
        }).catch(function (error) {
            setLoading(false);
            if (!automated) {
                setErrorList(['Failed to authenticate.']) ;
            }
        });
    }
    
    const signup = async () => {
        let password = await encryptPassword(state.password.value)
        const payload = {
            name: state.name.value.trim(),
            emailAddress: state.email.value.toLowerCase().trim(),
            password: password,
            registrationCode: state.code.value.trim()
        };

        setLoading(true);
        apiCall("POST", signUpApi, "", payload).then(function (response) {
            window.gtag("set", "user_properties", {
                user_email_dimension: payload.emailAddress
            });
            window.gtag("event", "sign_up", {
                email: payload.emailAddress
            });
            setLoading(false);
            window.location.href = `/register?proc=verify&e=${payload.emailAddress}&p=${password}`;
        }).catch(function (error) {
            console.log(error)
            const errorResponse = error?.response?.data;
            if (errorResponse?.message?.errorMessage) {
                setErrorList((errors) => [...errors, errorResponse.message.errorMessage]);
            } else {
                setErrorList((errors) => [...errors, errorResponse]);
            }
            setLoading(false);
        });
    };

    const resendVerification = () => {
        const payload = { 
            emailAddress: urlParams.get('e') 
        };
        setLoading(true);
        apiCall("POST", resendVerifyEmailApi, "", payload).then(function (response) {
            setLoading(false);
            if (response?.data == 'Confirmation Email Resent') {
                setSuccessMsg("Confirmation Resent! Please check your email.");
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const verifyAccount = () => {
        const payload = {
            emailAddress: urlParams.get('e'),
            confirmationCode: urlParams.get('c')
        }
        apiCall("POST", verifyEmailApi, "", payload).then(function (response) {
            if (response?.data?.data == 'Already Verified' || response?.data?.data == 'Verification Successful!')  {   
                setTitle(response?.data?.data);
                setSubTitle('Please enter your password again to login');
                setAccountVerified(true);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            console.log(error);
            setErrorList(['Failed to verify account. Please contact your Bungee admin.']);
        });
    }

    // ------------------------------------------------------------
    // Handlers
    // ------------------------------------------------------------
    const handleFocus = (e) => {
        const { name } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                isFocused: true,
            }
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name == 'email' && value) {
            validateEmail();
        } else if (name == 'password' && value) {
            validatePassword();
        } else if (name == 'confirmpassword' && value) {
            validateConfirmPassword();
        }
        setState((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                isFocused: prevState[name].value !== ''
            }
        }));
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
            }
        }));
        setFormReady(false);
    };

    const handleFormSubmit = () => {
        setErrorList([]);
        validateState();
    }

    // ------------------------------------------------------------
    // Validators
    // ------------------------------------------------------------
    const validateState = () => {
        let valid = true;
        if (!Object.values(state).every((field) => field.value.trim() !== '')) {
            setErrorList((errors) => [...errors, 'All fields are required']);
            valid = false;
        }
        if (Object.values(state).some((field) => field.isErrored === true)) {
            setErrorList((errors) => [...errors, 'Please fix all errors before proceeding']);
            valid = false;
        }
        if (!isPrivacyChecked) {
            setErrorList((errors) => [...errors, 'Please confirm that you have agreed to our Privacy Policy']);
            valid = false;
        }
        if (valid) {
            setFormReady(valid);
        }
    }

    const validateEmail = () => {
        if (!validator.isEmail(state['email'].value)) {
            setState((prevState) => ({
                ...prevState,
                ['email']: {
                    ...prevState['email'],
                    isErrored: true,
                    error: 'Invalid email'
                }
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                ['email']: {
                    ...prevState['email'],
                    isErrored: false,
                    error: ''
                }
            }));
        }
    };

    function validatePassword() {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/\\><':;|_~`+=-])[A-Za-z\d\^$*.[\]{}()?\-"!@#%&/\\><':;|_~`+=-]{8,}$/g;
        if (!regex.test(state['password'].value)) {
            setState((prevState) => ({
                ...prevState,
                ['password']: {
                    ...prevState['password'],
                    isErrored: true,
                    error: 'Password should contain Uppercase, Lowercase, Number, Special (@, $, !, %, *, ?, or &) and Minimum 8 characters.'
                }
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                ['password']: {
                    ...prevState['password'],
                    isErrored: false,
                    error: ''
                }
            }));
        }
    }

    function validateConfirmPassword() {
        if (state['confirmpassword'].value != state['password'].value) {
            setState((prevState) => ({
                ...prevState,
                ['confirmpassword']: {
                    ...prevState['confirmpassword'],
                    isErrored: true,
                    error: 'Passwords do not match'
                }
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                ['confirmpassword']: {
                    ...prevState['confirmpassword'],
                    isErrored: false,
                    error: ''
                }
            }));
        }     
    }

    // ------------------------------------------------------------
    // Renderings
    // ------------------------------------------------------------
    const renderFloatingLabelInput = (name, label, placeholder, type='text') => {
        return (<div className={`floating-input-container ${state[name].isFocused || state[name].value ? 'focused' : ''}`}>
            <label className={`floating-label`}>{label}</label>
            <input
                className={`floating-input form-control ${state[name].isErrored ? 'errored' : ''}`}
                type={type}
                placeholder={placeholder}
                name={name}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
            />
            <label className={`floating-input-error ${state[name].isErrored ? 'display' : ''}`}>{state[name].error}</label>
        </div>);
    };

    return (
        <div className="auth-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-center loginPage">
                    <div className="col-4">
                        <div className="row pad">
                            <div className="col-12 login-logo"></div>
                            <div className="col-md-12 text-start">
                                <h1 className="display-4 text-center mb-2 fw-bolder">
                                    {title}
                                </h1>
                                <p className="text-muted text-center mb-4">
                                    {subTitle}
                                </p>
                                <ul className={errorList.length ? "alert alert-danger" : "" } id="error-list" style={{display: errorList.length ? '': 'none'}}>
                                    {errorList.length ? errorList.map((ele) => <li>{ele}</li>) : ''}
                                </ul>
                                {successMsg ? (
                                    <div className="alert alert-success text-center" id="success-message" >
                                        {successMsg}
                                    </div>
                                ) : ''}
                                {(!proc || proc == null) && (<>
                                    <form>
                                        <div>
                                            <div className="mb-0">
                                                {renderFloatingLabelInput("name", "Name", "Enter your Name")}
                                            </div>

                                            <div className="mb-0">
                                                {renderFloatingLabelInput("email", "Email", "Enter your Email Address", "email")}
                                            </div>

                                            <div className="mb-0">
                                                {renderFloatingLabelInput("password", "Password", "Enter your Password", "password")}
                                            </div>

                                            <div className="mb-0">
                                                {renderFloatingLabelInput("confirmpassword", "Confirm Password", "Confirm your Password", "password")}
                                            </div>

                                            <div className="mb-0">
                                                {renderFloatingLabelInput("code", "Registration Code", "Enter your Registration Code")}
                                            </div>

                                            <div className="form-check p-0 mb-1">
                                                <div className="float-start">
                                                    <input
                                                        type="checkbox"
                                                        className="me-2"
                                                        checked={isPrivacyChecked}
                                                        onChange={() => setIsPrivacyChecked(!isPrivacyChecked)}
                                                    />
                                                </div>
                                                <label className="form-check-label" >
                                                    I Agree to
                                                    &nbsp;<span className="btn-link text-decoration-none cursor-pointer" onClick={e => setShowPrivacyPolicy(true)}>Privacy Policy</span>
                                                    &nbsp;&nbsp;<a target="_blank" href={process.env.PUBLIC_URL + "/privacy-policy.pdf"}><FontAwesomeIcon icon={faFileDownload} /></a>
                                                </label>
                                            </div>

                                            <div className="d-grid gap-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-lg btn-dark btn-block mb-4"
                                                    onClick={handleFormSubmit}
                                                >
                                                    Sign Up
                                                </button>
                                            </div>
                                        </div>

                                        <p className="forgot-password text-center">
                                            Already have an account? <Link to="/" className="text-decoration-none">Sign In</Link>
                                        </p>
                                    </form> 
                                </>)}
                                {(proc && proc == 'verify') && (<>
                                    <div>
                                        <div className="d-grid gap-2">
                                            <button
                                                type="button"
                                                className="btn btn-lg btn-dark btn-block mb-4"
                                                onClick={() => navigate(pageRoutes.loginPage)}
                                            >
                                                Return to Login
                                            </button>
                                        </div>
                                    </div>

                                    <p className="text-center">
                                        Didn't receive a confirmation email? <a className="text-decoration-none" href="#" onClick={resendVerification}>Resend Confirmation</a>.
                                    </p>
                                    <p className="text-center">
                                        If you've already verified your account, <a className="text-decoration-none" href="#" onClick={() => window.location.reload()}>refresh</a> this page.
                                    </p>
                                </>)}
                                {(proc && proc == 'confirmaccount') && (<>
                                    {accountVerified ? (<>
                                        <div className="d-grid gap-2">
                                            <div className="mb-0">
                                                {renderFloatingLabelInput("password", "Password", "Enter your Password", "password")}
                                            </div>

                                            <button
                                                type="button"
                                                className="btn btn-lg btn-dark btn-block mb-4"
                                                onClick={login}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </>) : (<> 
                                        <div className="text-center mb-4">
                                            <div className="loader2"></div>
                                        </div>
                                    </>)}
                                    <div className="d-grid gap-2">
                                        <button
                                            type="button"
                                            className="btn btn-lg btn-dark btn-block mb-4"
                                            onClick={() => navigate(pageRoutes.loginPage)}
                                        >
                                            Return to Login
                                        </button>
                                    </div>
                                </>)}
                            </div>
                            <div className="col-md-12">
                                <p className="text-center login-footer">
                                    <LoginFooter showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy} />
                                </p>
                            </div>
                        </div>
                        <Loader loading={loading} />
                    </div>
                    <div className="col-8 d-none d-lg-block pe-0">
                        <div className="h-100">
                            <div className="bg-cover-container"><div className="bg-cover"></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
