import React, { useState } from "react";
import Header1 from "../common/Header1/Header1";
import * as pageRoutes from "../../pageRoutes";

const ErrorPage = () => {

    let breadcrumbConfig = [{
        href: pageRoutes.loginPage,
        title: "Home"
    }];
    if (localStorage.errorUrl) {
        breadcrumbConfig.push({
            href: localStorage.errorUrl,
            title: "Go Back to Error Page"
        });
    }
    breadcrumbConfig.push({
        href: null,
        title: "Error"
    });

    return (<>
        <div className="vh-100">
            <Header1
                breadcrumbConfig={breadcrumbConfig}
                onlyBreadCrumb={true}
                showSavedFilters={false}
                hideRefresh={true}
            />
            <div className="page-container-default h-100">
                <div className="custom-container h-100">
                    <div className="d-flex align-items-center justify-content-center h-75">
                        <div className="text-center">
                            <h1 className="display-1 fw-bold text-dark">Error</h1>
                            <p className="fs-3"> <span className="text-danger">Oops! It seems we're experiencing some technical difficulties at the moment. Our team is working hard to resolve the issue. Please try again later. Thank you for your patience and understanding.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default ErrorPage;